// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import '@/assets/styles/tailwind.css';
import './registerServiceWorker';
import router from './router';
import i18n from './i18n'; // Import i18n instance
import helper from './helper/helper'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimesCircle,faCircleCheck,faMagnifyingGlass, faClipboard,faExclamationTriangle, faSignOutAlt, faDownload, faSpinner, faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Redirect logic
const defaultDomain = 'wonderful-wave-058349a00.5.azurestaticapps.net';  // Replace with your actual Azure-generated domain
const customDomain = 'https://csv.pantona-dev.com';   // Replace with your custom domain

if (window.location.hostname === defaultDomain) {
  window.location.href = customDomain + window.location.pathname;
}

library.add(faTimesCircle,faCircleCheck,faMagnifyingGlass,faClipboard,faExclamationTriangle,faSignOutAlt,faDownload, faSpinner, faBookOpen);

const app = createApp(App);
app.component("font-awesome-icon",FontAwesomeIcon);
app.config.globalProperties.$helpers = helper;

app.use(router).use(i18n).mount('#app');