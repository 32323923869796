<!-- src/components/DynamicProgressBar.vue -->
<template>
    <transition name="fade" appear> 
      <div class="modal-backdrop" v-show="ShowModal">

      </div>
    </transition>
    <transition name="pop" appear>
      <div class="modal rounded" v-show="ShowModal">
          <section class="modal-body">
              <slot name="body">
                  <div class="modal-icon flex flex-row justify-center"><font-awesome-icon class="icon-style" icon="times-circle" /></div>
                  <div id="modalTitle" class="modalTitle text-center pt-2 text-2xl">{{ $t('modalTitleError') }}</div>
                  <div id="modalContent" class="modalTitle text-center pt-2 text-base">{{ InvalidDataTitle }}</div>
                  <div id="invalidData" class="mt-3 pb-6 flex flex-col items-center">
                      <p v-for="(row) in displayedData" :key="row.id" class="text-red-600">
                          {{ row.username }}
                      </p>
                  </div>
                  <div v-if="totalPages > 1" class="mt-4 mr-4 flex items-center justify-center">
                      <button @click="prevPage" :disabled="currentPage === 1" :class="{ 'cursor-default' : currentPage === 1}"  class="text-sm mr-2 px-4 py-2 bg-gray-300 rounded">{{$t('paginationPrev')}}</button>
                      <div class="flex flex-row" v-if=" currentPage != 1 && firstPage != 1">
                      <div class="text-sm mx-1 px-2 py-2 bg-gray-300 rounded cursor-pointer" :class="{ 'font-semibold': page === currentPage }" @click="goToPage(1)">1</div>    
                      <div class="text-sm mx-1 px-2 py-2 cursor-default" >...</div>      
                      </div>
                      <!-- Use <template> tag for v-for directive -->
                      <template v-for="page in dynamicPageNumbers" :key="page"  >
                      <div
                          class="text-sm mx-1 px-2 py-2 bg-gray-300 rounded cursor-pointer"
                          :class="{ 'font-semibold': page === currentPage }"
                          @click="goToPage(page)"
                      >
                          {{ page }}
                      </div>
                      </template>
                      <div class="flex flex-row" v-if="totalPages > numShown && currentPage != totalPages && (totalPages - currentPage >= numShown / 2)">
                      <div class="text-sm mx-1 px-2 py-2 cursor-default" >...</div>      
                      <div class="text-sm mx-1 px-2 py-2 bg-gray-300 rounded cursor-pointer" :class="{ 'font-semibold': page === currentPage }" @click="goToPage(totalPages)">{{ totalPages }}</div>    
                      </div>
                      <button @click="nextPage" :disabled="currentPage === totalPages" :class="{ 'cursor-default' : currentPage === totalPages}" class="text-sm ml-2 px-4 py-2 bg-gray-300 rounded">{{$t('paginationNext')}}</button>
                  </div>
              </slot>
          </section>
          <footer class="modal-footer flex pb-5">
              <slot name="footer">
                  <button type="button" class="rounded bg-dark-blue btn-close px-2 py-2 text-white" @click="close">{{$t('modalClose')}}</button>
              </slot>
          </footer>
      </div>
    </transition>
</template>
  
<script>
  export default {
    props: {
        InvalidData: Array,
        ShowModal : Boolean,
        InvalidDataTitle : String
    },
    name: 'ModalInvalidData',
    data() {
        return {
        pageSize: 10,
        currentPage: 1,
        jumpToPage: 1,
        numShown : 5,
        firstPage : 0
        };
    },
    computed:{
      displayedData() {
        const startIdx = (this.currentPage - 1) * this.pageSize;
        const endIdx = this.currentPage * this.pageSize;
        return this.InvalidData.slice(startIdx, endIdx);
      },
      totalPages() {
        return Math.ceil(this.InvalidData.length / this.pageSize);
      },
      dynamicPageNumbers() {
        // const remainingPages = Math.min(10, this.totalPages - this.currentPage);
        // return Array.from({ length: this.totalPages }, (_, index) => index + 1);
        const numShown = Math.min(this.numShown, this.totalPages);
        let first = this.currentPage - Math.floor(numShown / 2);
        first = Math.max(first, 1);
        first = Math.min(first, this.totalPages - numShown + 1);
        this.setFirstPage(first);
        console.log(first);
        return [...Array(numShown)].map((k,i) => i + first);
      },
    },
    methods: {
      close() {
        this.currentPage = 1,
        this.$emit('close');
      },
      setFirstPage(page){
        this.firstPage = page;
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      goToPage(page) {
        this.currentPage = page;
      },
    },
  };
</script>
<style scoped>
  .icon-style{
    font-size: 100px;
    color: red;
  }
  .modal-backdrop {
    /* position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center; */

    content: '';
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 998;
    background: #2c3e50;
    opacity: 0.6;
  }

  .modal {
    /* position:relative;
    background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    min-height: 350px;
    max-height: 600px; */

    position: absolute;
    top: 30%;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
    width: 100%;
    min-height: 350px;
    max-width: 500px;
    max-height: 600px;
    height: auto;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    background: #FFF;
    z-index: 999;
    overflow-y: auto;
  }

  .modal-header{
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-footer {
    /* position:absolute; */
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    /* bottom: 0;
    left: 0;
    right: 0; */
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }
  .fade-enter-active,.fade-leave-active {
    transition: opacity .4s linear;
  }
 .fade-enter-from,.fade-leave-to {
     opacity: 0;
  }
  .pop-enter-active,.pop-leave-active {
    transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
  }
  .pop-enter-from,.pop-leave-to {
    opacity: 0;
    transform: scale(0.3) translateY(-50%);
  }

  .btn-close {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  @media only screen and (max-width: 1900px) {
    .modal{
      top: 15%;
    }
  }

  @media only screen and (max-width: 1366px) {
    .modal{
      top: 35%;
    }
  }

</style>

