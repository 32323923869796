<!-- src/components/Login.vue -->
<template>
  <div class="bg-dark-blue">
    <div class="flex items-center justify-center h-screen">
      <form @submit.prevent="login" class="login-card bg-white p-8 shadow-md rounded-md">
        <div class="logo-image flex justify-center">
          <img class="w-52 h-auto" v-bind:src="'/img/icons/' + imageLogo" /> 
        </div>
        <h1 class="text-xl pt-3 font-semibold text-center ">{{ $t('app_title') }}</h1>
        <h1 class="text-xl pt-3 font-semibold text-center mb-6">{{ $t('app_name') }}</h1>
        <div class="mb-2 text_error">
          <div class="text-red-500 mb-4" v-if="errorMessage !== ''">
            {{ errorMessage }}
          </div>
        </div>
        <div class="mb-4">
          <!-- <label for="password" class="block text-gray-700 font-semibold">{{ $t('password') }}:</label> -->
          <div ref="formParent" v-bind:class="isFocusedPassword ? 'border-dark-blue' : ''" class="flex items-center border-2 rounded">
            <img class="pl-1 form-logo" v-bind:src="'/img/icons/' + passwordIco" /> 
            <input @focus="isFocusedPassword = true" @blur="isFocusedPassword = false" v-model="password" type="password" id="password" :placeholder="$t('password')" class="custom-login-form w-full p-2">
          </div>
        </div>
  
        <button type="submit" class="w-full bg-dark-blue text-white p-2 rounded hover:bg-blue-700">{{ $t('login') }}</button>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  data() {
    return {
      imageLogo:'lgate-logo.svg',
      usernameIco:'user.png',
      passwordIco:'password.png',
      password: '',
      isFocusedUsername: false,
      isFocusedPassword: false,
      errorMessage: '', // Add error message state
    };
  },
  methods: {
    async login() {
      const url = process.env.VUE_APP_BASE_URL_SERVER+'/login';
      let isAuthenticated = false;
      let password = this.password;


      if (!this.password) {
        this.errorMessage = this.$t('password_required');
        return;
      }

      // Reset error message
      this.errorMessage = '';


      try {
        const response = await axios.post(url, { password }).then(res => res.data).catch(err => console.error(err));
        if(response.valid == true){
          const dateTime = new Date();
          let date = dateTime.getFullYear()+'-'+String(dateTime.getMonth()+ 1).padStart(2,"0")+'-'+String(dateTime.getDate()).padStart(2,"0")+' '+String(dateTime.getHours()).padStart(2, "0")+':'+String(dateTime.getMinutes()).padStart(2, "0");
          isAuthenticated = true;
          localStorage.session_data = this.generateUniqueRandomString(10);
          localStorage.session_expired = moment(date).add(response.session_expiry, 'M').format("YYYY-MM-DD HH:mm");
          localStorage.setItem('client_id', response.uuid);
          localStorage.setItem('api_key', response.api_key);
          localStorage.setItem('system_version', response.system_version);
          localStorage.setItem('isUpload', "0");
        }
      } catch (err) {
        this.error = err.message;
      }

      if (isAuthenticated) {
        this.$router.push('/csv-reader');
      } else {
        this.errorMessage = this.$t('invalidCredentials');
      }
    },
    generateUniqueRandomString(length) {
      const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let randomString = '';

      // Generate a timestamp or a UUID to ensure uniqueness
      const uniqueIdentifier = Date.now().toString(36); // Using timestamp
      
      // Append the unique identifier to the random string
      randomString += uniqueIdentifier;

      // Generate random characters to fill the remaining length
      for (let i = 0; i < length - uniqueIdentifier.length; i++) {
        const randomIndex = Math.floor(Math.random() * charset.length);
        randomString += charset[randomIndex];
      }

      return randomString;
    },
  },
};
</script>

<style scoped>
.login-card{
  width: 100%;
  max-width: 470px;
}
.form-logo{
  width:20px;
  height:auto;
}
.custom-login-form:focus{
  outline-width:0;
}
.text_error{
  min-height: 2em
}
</style>
