<template>
  <div class="bg-dark-blue min-h-screen flex items-center justify-center">
    <div class="relative custom-width bg-white p-8 rounded shadow-md height-form ">
      <HeaderButtonComponent/>
      <div class="mt-7">
        <h1 class="text-2xl font-semibold mt-4 mb-4 text-center">{{ $t('teacher_header1') }}</h1>
        <h2 class="text-xl font-semibold  text-center align-center   ">{{ $t('teacher_header2') }}</h2>
      </div>
      <div class="search-container relative flex flex-row justify-end">
        <input type="text" id="table_search" :placeholder="$t('th_name')" v-on:keyup.enter="filterName" v-model="tableSearch" class="w-64 m mt-4 mb-4 p-2 pr-11 text-left text-black border border-black rounded focus:outline-none focus:border-blue-500 placeholder-gray-600"/>
        <font-awesome-icon icon="magnifying-glass" @click="filterName" class="absolute text-2xl icon-properties cursor-pointer" />
      </div>
      <div class="table-container overflow-x-auto border border-black">
        <!-- <table class="table-fixed bg-white border border-gray-300 shadow-sm" :class="[classheader.length <= 3 ? 'w-full' : 'w-max']"> -->
        <table class="table-fixed bg-white border border-gray-300 shadow-sm" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'w-full' : 'w-max']">
          <thead>
            <tr>
              <th class="sortable-header table-header px-4 py-2 text-center border border-black text-white text-sm" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'checkbox-width' : '']">
                {{ $t('th_activate') }}
                
              </th>
              <th class="sortable-header table-header px-4 py-2 text-center border border-black text-white text-sm" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'name-width' : '']" @click="toggleSort('name')">
                {{ $t('th_name') }}
                <span v-if="shouldShowIndicator('name', 'asc')">▲</span>
                <span v-if="shouldShowIndicator('name', 'desc')">▼</span>
              </th>
              <th class="sortable-header table-header px-4 py-2 text-center border border-black text-white text-sm" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'username-width' : '']" @click="toggleSort('username')">
                {{ $t('th_email') }}
                <span v-if="shouldShowIndicator('username', 'asc')">▲</span>
                <span v-if="shouldShowIndicator('username', 'desc')">▼</span>
              </th>
              <th v-for="(value, index) in classheader" :key="index" class="sortable-header table-header px-4 py-2 text-center border border-black text-white text-sm" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'class-width' : '']" @click="toggleSort(`kelas${index + 1}`)">
                {{ $t('kelas') }} {{ index + 1 }}
                <span v-if="shouldShowIndicator(`kelas${index + 1}`, 'asc')">▲</span>
                <span v-if="shouldShowIndicator(`kelas${index + 1}`, 'desc')">▼</span>
              </th>          
              <!-- <th v-for="(value, index) in header" :key="index" class="sortable-header table-header px-4 py-2 text-center border border-black text-white text-sm  " @click="toggleSort('checkbox')">
                {{ value }}
                <span v-if="shouldShowIndicator('checkbox')">▲▼</span>
              </th> -->
            </tr>
          </thead>
          <tbody class="border border-black">
            <tr v-for="(row, rowIndex) in displayedData" :key="rowIndex" class="hover:bg-gray-50 transition-all border border-black" >
              <td class="border border-black px-2 py-1 text-center text-sm leading-3" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'checkbox-width' : '']"><input type="checkbox" class="h-5 w-5" :value="row.id" v-model="checkboxTeachers"/></td>
              <td class="border border-black px-2 py-1 text-center text-sm leading-3" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'name-width' : '']">{{ row.name  || '' }}</td>
              <td class="border border-black px-2 py-1 text-center text-sm leading-3" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'username-width' : '']">{{ row.username  || ''    }}</td>
              <td v-for="(header, index) in classheader " :key="index" class="border border-black px-2 py-1 text-center text-sm leading-3" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'class-width' : '']">
                {{ row[`kelas${index + 1}`] || "" }}
              </td>
            </tr>
           
          </tbody>
        </table>
        <div v-if="csvDataDisplay.length <= 0">
          <h2 class="text-center font-bold"> {{ $t('noTeacherFound') }}</h2>
        </div>
      </div>
          <!-- Pagination controls at the bottom right corner -->
          <div v-if="totalPages > 1" class="mt-4 mr-4 flex items-center justify-end">
            <button @click="prevPage" :disabled="currentPage === 1" class="mr-2 px-4 py-2 bg-gray-300 rounded text-sm">{{$t('paginationPrev')}}</button>
            <div class="flex flex-row" v-if=" currentPage != 1 && firstPage != 1">
              <div class="mx-1 px-2 py-2 bg-gray-300 rounded cursor-pointer" :class="{ 'font-semibold': page === currentPage }" @click="goToPage(1)">1</div>    
              <div class="mx-1 px-2 py-2 cursor-default" >...</div>      
            </div>
            <!-- Use <template> tag for v-for directive -->
            <template v-for="page in dynamicPageNumbers" :key="page"  >
              <div
                class="text-sm mx-1 px-2 py-2 bg-gray-300 rounded cursor-pointer"
                :class="{ 'font-semibold': page === currentPage }"
                @click="goToPage(page)"
              >
                {{ page }}
              </div>
            </template>
            <div class="flex flex-row" v-if="totalPages > pageSize && numShown != totalPages && (totalPages - currentPage >= numShown / 2)">
              <div class="text-sm mx-1 px-2 py-2 cursor-default" >...</div>      
              <div class="text-sm mx-1 px-2 py-2 bg-gray-300 rounded cursor-pointer" :class="{ 'font-semibold': page === currentPage }" @click="goToPage(totalPages)">{{ totalPages }}</div>    
            </div>
            <button @click="nextPage" :disabled="currentPage === totalPages" class="text-sm ml-2 px-4 py-2 bg-gray-300 rounded">{{$t('paginationNext')}}</button>
          </div>
          <p class=" text-l pt-2 font-semibold text-center my-2">{{ $t('total_records') }} : {{ datatableCounts.totalRecords }} {{ $t('satuan') }} ({{ $t('total_filtered') }} : {{ datatableCounts.totalFiltered }} {{ $t('satuan') }}、{{ $t('total_invalid') }} : {{ datatableCounts.totalInvalid }} {{ $t('satuan') }}) </p>
          <p class=" text-l pt-2 font-semibold text-center">  {{ $t('notPerformSSO') }} </p>

        <div class="form-import flex flex-row justify-center mt-4 mb-2 gap-5">
          <button @click="toCsvReader" class="csv-group-button px-4 py-2 bg-dark-blue text-white p-2 rounded hover:bg-blue-700">{{ $t('button_back') }}</button>

          <button @click="toCsvStudent" class="csv-group-button px-4 py-2 bg-dark-blue text-white p-2 rounded hover:bg-blue-700">{{ $t('button_next') }}</button>
        </div>
        
        <div v-if="!isOnline" class="text-center text-red-600 mt-4">
          {{$t('youareoffline')}}
        </div>

        <ExportLogComponent :isExportLog="export_log"/>
        <SystemVersionComponent :SystemVersion="system_version"/>
        <ModalAlertComponent :Message="errorMessage" :ShowModal="showAlertModal" @close="closeAlert"/>
        <ModalWarningComponent :Message="errorMessage" :ShowModal="showWarningModal" @cancel="closeWarning" @ok="okWarning"/>

    </div>
  </div>
</template>

<script>
  import Dexie from 'dexie';
  import ModalAlertComponent from './ModalAlert.vue';
  import ModalWarningComponent from './ModalWarning.vue';
  import SystemVersionComponent from './SystemVersion.vue';
  import ExportLogComponent from './ExportLog.vue';
  import HeaderButtonComponent from './headerButton.vue';




  const db = new Dexie('CSVDataDB');
  
  db.version(4).stores({
    csvData : '++id, *data',
    headerData: '++id, *header',
    exportedCsv :'++id, *data',
    localLog :'++id, *data'
  });

  export default {
    components:{
      ModalAlertComponent,
      ModalWarningComponent,
      SystemVersionComponent,
      ExportLogComponent,
      HeaderButtonComponent
    },
    data() {
      return {
        sortColumn: null,
        tableSearch : '',
        logoutIcon:'Logout.png',
        csvData: [],
        csvDataNew: [],
        csvDataDisplay: [],
        header : [],
        pageSize: 10,
        currentPage: 1,
        jumpToPage: 1,
        totalRecords: 0,
        totalFiltered: 0,
        totalInvalid: 0,
        classheader: [],
        isChecked: true,
        numShown : 10,
        firstPage : 0,
        sortOrders: {},
        isModalVisible : false,
        system_version : process.env.VUE_APP_SYSTEM_VERSION,
        checkboxTeachers: [],
        showAlertModal : false,
        showWarningModal : false,
        errorMessage : '',
        classHeaderCount : 0,
        isOnline: navigator.onLine,
      };
    },
    mounted() {
     this.toggleSort('name');
    },
    computed:{
      displayedData() {
        const startIdx = (this.currentPage - 1) * this.pageSize;
        const endIdx = this.currentPage * this.pageSize;
        return this.csvDataDisplay.slice(startIdx, endIdx);
      },
      totalPages() {
        return Math.ceil(this.csvDataDisplay.length / this.pageSize);
      },
      dynamicPageNumbers() {
        // const remainingPages = Math.min(10, this.totalPages - this.currentPage);
        // return Array.from({ length: remainingPages }, (_, index) => this.currentPage + index)
        const numShown = Math.min(this.numShown, this.totalPages);
        let first = this.currentPage - Math.floor(numShown / 2);
        first = Math.max(first, 1);
        first = Math.min(first, this.totalPages - numShown + 1);
        this.setFirstPage(first);
        return [...Array(numShown)].map((k,i) => i + first);
      },
      datatableCounts() {
        return {
          totalRecords: this.totalRecords,
          totalFiltered: this.totalFiltered,
          totalInvalid: this.totalInvalid,
        };
      },
    },  
    methods: {
      filterEmail(){
        if(this.tableSearch != ''){
          this.csvDataDisplay = this.csvDataNew.filter((item) => {
              return item.username.includes(this.tableSearch);
          });
          let searchWord = '"' + this.tableSearch + '"';
          this.logLocal("info","Searching by email data in teacher page", 'Searching by email ' + searchWord  + ' word',  this.isOnline);
        }else{
          this.csvDataDisplay = this.csvDataNew;
        }
      },
      filterName(){
        if(this.tableSearch != ''){
          this.csvDataDisplay = this.csvDataNew.filter((item) => {
              return item.name.includes(this.tableSearch);
          });
          let searchWord = '"' + this.tableSearch + '"';
          this.logLocal("info","Searching by name data in teacher page", 'Searching by name ' + searchWord  + ' word',  this.isOnline);
        }else{
          this.csvDataDisplay = this.csvDataNew;
        }
      },
      setFirstPage(page){
        this.firstPage = page;
      },
      async  logLocal(level, action, description, isOnline) {
        const logsToAdd = [];
        let timestamp = Date.now();          
          const mappedlogLocal = {
                datetime : timestamp,
                client_id : this.client_id,
                level: level.toUpperCase(),
                action: action,
                description : description
          }
          logsToAdd.push(mappedlogLocal); 

          if (isOnline == false) {
              await db.localLog.bulkAdd(logsToAdd);
          } else {
              return "";
          }
      },
      logout(){
        this.logLocal("info","Logout in teacher page", 'Success, Logout, data reseted', this.isOnline);
        localStorage.removeItem('session_data');
        localStorage.removeItem('client_id');
        localStorage.removeItem('api_key');
        localStorage.removeItem('input_year');
        localStorage.removeItem('system_version');
        localStorage.removeItem('isUpload');
        localStorage.removeItem('is_filter');
        localStorage.removeItem('checkedStudents');
        localStorage.removeItem('checkedTeachers');
        db.csvData.clear();
        db.headerData.clear();
        this.$router.push('/');
      },
      async loadCSVDataAndHeader() {
        try {
          const prevCheckedTeacher = localStorage.checkedTeachers?.split(',').map(Number);
          // Fetch CSV data from the 'csvData' store 
          this.csvData = await db.csvData
                        .filter(row => row.classRole !== "児童生徒")
                        .filter(row => row.username.trim() !== '')
                        .filter(row => row.enabledUser !== "0" && row.isLocal !== "1")
                        .toArray();          
            // total semua 
          this.totalRecords = await db.csvData
                      .filter(row => row.classRole !== "児童生徒")
                      .filter(row => row.username.trim() !== '')
                      .count();

          this.totalFiltered = await db.csvData
                   .filter(row => row.classRole !== "児童生徒")
                   .filter(row => row.username.trim() !== '')
                   .filter(row => row.enabledUser !== '')
                   .filter(row => row.isLocal !== '')
                   .filter(row => row.schoolName !== '')
                   .filter(row => row.renewName !== '')
                   .filter(row => row.renewPassword !== '')
                   .filter(row => row.renewClass !== '')
                   .filter(row => row.enabledUser !== "0")
                   .filter(row => row.isLocal !== "1")
                   .count()

          this.totalInvalid = this.totalRecords - this.totalFiltered;

          // // Fetch header data from the 'headerData' store
          // const headerData = await db.headerData.toArray();
          // this.header = headerData.length > 0 ? JSON.parse(headerData[0].header) : [];

        // Fetch header data from the 'headerData' store with a filter for properties starting with "classNumber"
          const classheaderData = await db.headerData
            .where('header')
            .notEqual('')
            .toArray();

          if (classheaderData.length > 0) {
              const headerValue = classheaderData[0].header;
              const parsedHeader = JSON.parse(headerValue);
              const filteredClassNumbers = parsedHeader.filter(property => property.startsWith("kelas"));
              this.classheader = filteredClassNumbers;
          } else {
            this.classheader = [];
          }

            this.csvDataNew =  await db.csvData
                        .filter(row => row.classRole !== "児童生徒")
                        .filter(row => row.enabledUser !== "0")
                        .filter(row => row.isLocal !== "1")
                        .toArray();

            const mappedcsvDataNew = this.csvDataNew.map(entry => {
        

              const mappedEntry = {
                  id: entry.id,
                  name: entry.name,
                  username: entry.username,
              };

              this.classheader.forEach((header, index) => {
                  mappedEntry[`kelas${index + 1}`] = entry[`kelas${index + 1}`];

                  this.classHeaderCount++;
              });

              if(prevCheckedTeacher == undefined){
                this.checkboxTeachers.push(entry.id);
              }else{
                if(prevCheckedTeacher.includes(entry.id)){
                  this.checkboxTeachers.push(entry.id);
                }
              }
              return mappedEntry;
          });
          let mappedcsvDataNewSorted = this.sortArray(mappedcsvDataNew, 'name', 'asc')

          this.csvDataDisplay = mappedcsvDataNewSorted;
          this.csvDataNew = mappedcsvDataNewSorted;

        } catch (error) {
          this.errorMessage = this.$t("errorLoadingData")+' '+error;
          this.logLocal("error",this.errorMessage, 'Error, loading teacher data from IndexedDb', this.isOnline);
          this.showAlertModal = true;
        }
      },
      async toCsvReader(){
        this.logLocal("warn","Warning to Reader Page modal ", 'Modal data will reset popup, if continue to Reader Page', this.isOnline);
        this.errorMessage = this.$t("warningtocsvreader");
        this.showWarningModal = true;
      },
      toCsvStudent(){
        this.logLocal("info","Go to Student Page", 'Success, Go to Student Page', this.isOnline);
        localStorage.checkedTeachers = this.checkboxTeachers;
        this.$router.push('/csv-student');
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      goToPage(page) {
        this.currentPage = page;
      },
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      },
      showAlert(){
        this.showAlertModal = true;
      },
      closeAlert(){
        this.showAlertModal = false;
      },
      showWarning(){
        this.showWarningModal = true;
      },
      closeWarning(){
        this.showWarningModal = false;
        this.logLocal("info","Cancel button clicked", 'Cancel button in modal warning is clicked', this.isOnline);
      },
      okWarning(){
        this.showWarningModal = false;
        this.logLocal("info","Back to Reader Page", 'Success, Back to Reader Page, Data Reseted', this.isOnline);
        db.csvData.clear();
        db.headerData.clear();
        localStorage.setItem('isUpload', "0");
        this.$router.push('/csv-reader');
      },
      toggleSort(column) {
        // Tambahkan logic untuk sorting
        if (this.sortColumn === column) {
          // Toggle between ascending and descending order
          this.sortOrders[column] = this.sortOrders[column] === 'asc' ? 'desc' : 'asc';
          this.csvDataNew = this.sortArray(this.csvDataNew, column, this.sortOrders[column]);
          this.csvDataDisplay = this.csvDataNew;
        } else {
          this.sortColumn = column;
          // Set default sorting order to ascending
          this.sortOrders[column] = 'asc';
          this.csvDataNew = this.sortArray(this.csvDataNew, column, this.sortOrders[column]);
          this.csvDataDisplay = this.csvDataNew;
        }
      },

      sortArray(array, column, order) {
        return array.sort((a, b) => {
          const valueA = a[column];
          const valueB = b[column];

          if (typeof valueA === 'number' && typeof valueB === 'number') {
            // Numerical sorting
            return (order === 'asc' ? 1 : -1) * (valueA - valueB);
          } else {
            // Alphabetical sorting
            const stringA = String(valueA).toLowerCase();
            const stringB = String(valueB).toLowerCase();
            return (order === 'asc' ? 1 : -1) * stringA.localeCompare(stringB, 'ja-JP');
            // ja-JP-u-co-search
          }
        });
      },

      shouldShowIndicator(column, order) {
        // Check if the column is the current sorting column
        if (this.sortColumn === column) {
          // If the order is not explicitly set, set it to asc for the first click
          if (!this.sortOrders[column]) {
            this.sortOrders[column] = 'asc';
          }
          // Check if the order matches the current sorting order
          return this.sortOrders[column] === order;
        }
        return false;
      },
     
      async deleteOldLogs() {
        const currentDate = Date.now();
        const threeDaysBefore = new Date(currentDate);
        threeDaysBefore.setDate(threeDaysBefore.getDate() - 3);
        const threeDaysBeforeTimestamp = threeDaysBefore.getTime();

        await db.localLog
          .filter(row => row.datetime < threeDaysBeforeTimestamp)
          .delete();
      },
    },
    created() {
      this.deleteOldLogs();
      this.loadCSVDataAndHeader();
    },
    unmounted() {
  },
  }
</script>

<style scoped>
.checkbox-width{
  width: 22%;
}
.name-width{
  width: 60%;
}
.username-width {
  width: 70%;
}
.class-width {
  width: 33.33%;
}

.checkbox-width-max{
  width: 6%;
}
.name-width-max{
  width: 20%;
}
.username-width-max{
  width: 20%;
}
.class-width-max{
  width: 10%;
}
  
.table-container {
  overflow-x: auto;
  max-width: 1200px;
  height: 100%;
  max-height: 370px;
  overflow-y: auto;
  margin-left: auto;
  margin-right: auto;

}
.custom-width{
  min-width: 1200px;
}

.sortable-header{
    cursor: pointer;
    user-select: none;
  }

  .table-header {
    background-color: #004c97;
  }

.rotate-180 {
  transform: rotate(180deg);
}
.logout-container{
  position: absolute;
  top: 10px;
  right: 15px;
}
.logout-icon{
  width:25px;
  height:auto;
}
.height-form{
  min-height:85vh;
}
.icon-properties{
  bottom: 25px;
  right: 9px;
}
@media only screen and (max-width: 1900px) {
  .table-container{
    max-width: unset;
    max-height: 300px;
  }
  .custom-width{
    width: 100%;
    max-width: 90%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
</style>
