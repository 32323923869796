<!-- src/components/DynamicProgressBar.vue -->
<template>
    <!-- <div v-if="isVisible" class="bg-white p-8 shadow-md rounded-md mb-4"> -->
      <!-- <div class="import-text-header">
        <p class="text-2xl pt-3 font-semibold text-center mb-2">{{$t('loadingbarText1')}}</p>
        <div class="mt-8 w-full h-6 bg-gray-200 rounded-full dark:bg-gray-700">
          <div :style="{ width: `${progress}%` }" class="h-6 bg-green-600 text-xl font-xl text-blue-100 text-center p-0.5 leading-none rounded-full">{{progress}}%</div>
        </div>
      </div>
    </div> -->
    <div v-if="isVisible" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div class="bg-white p-8 shadow-md rounded-md">
      <div class="import-text-header">
        <p class="text-2xl pt-3 font-semibold text-center mb-2">{{$t('loadingbarText1')}}</p>
        <div class="mt-8 w-full h-6 bg-gray-200 rounded-full dark:bg-gray-700">
          <div :style="{ width: `${progress}%` }" class="progressbar h-6  text-xl font-xl text-blue-100 text-center p-0.5 leading-none rounded-full">{{progress}}%</div>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  export default {
    props: {
      progress: {
        type: Number,
        required: true,
      },
      isVisible: {
        type: Boolean,
        required: true,
      },
    },
  };
  </script>
  
<style scoped>
.progressbar{
  background-color: #004c97;
}
</style>

