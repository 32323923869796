// src/i18n.js
import { createI18n } from 'vue-i18n';

const messages = {
  en: require('./locales/en.json'),
  ja: require('./locales/ja.json')
};

const i18n = createI18n({
  locale: 'ja', // default locale
  fallbackLocale: 'en',
  messages
});

export default i18n;
