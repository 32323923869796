import axios from "axios";
import moment from "moment";
import Dexie from 'dexie';
import router from "@/router";

const db = new Dexie('CSVDataDB');
db.version(4).stores({
  csvData : '++id, *data',
  headerData: '++id, *header',
  exportedCsv :'++id, *data',
  localLog :'++id, *data'
});

const state = {
  sessionExpiry : process.env.VUE_APP_SESSION_EXPIRED
};

const helper = {
    logError(level, action, error_detail, api_key, isonline) {
      const url = process.env.VUE_APP_BASE_URL_SERVER+'/api/save-log';
      let client_id = localStorage.getItem('client_id');

        const logData = {
          level: level,
          action: action,
          error_detail: error_detail,
          client_id: client_id,
          api_key: api_key,
          isonline: isonline
        };
  
        if(isonline == true){
          axios.post(url, logData)
          .then(response => {
            console.log(response.data);
          })
          .catch(error => {
            console.error('Error saving log:', error);
          });
        }
  
     
    },
    checkSession(){
      const current_date = moment();
      const expiry_date = moment(localStorage.session_expired);
      console.log('Expiry Date',current_date,expiry_date);
      if(current_date.isAfter(expiry_date)){
        console.log('Expired Session');
        localStorage.removeItem('session_data');
        localStorage.removeItem('session_expired');
        localStorage.removeItem('client_id');
        localStorage.removeItem('api_key');
        localStorage.removeItem('routes');
        localStorage.removeItem('isUpload');
        db.csvData.clear();
        db.headerData.clear();
        router.push('/');
      }else{
        console.log('Valid Session');
        localStorage.session_expired = moment(expiry_date).add(state.sessionExpiry, 'M').format("YYYY-MM-DD HH:mm");
      }
  }

};
  
export default helper;