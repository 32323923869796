<template>
  <div class="bg-dark-blue">

      <div class="px-24 flex flex-col items-center justify-center h-screen max-h-full ">
        <div class="bg-white p-8 my-8 shadow-md rounded-md min-w-full">

          <h1 class="text-3xl pt-3 font-semibold text-center mb-4">CSV Data Preview</h1>
          <div class="table-container">
            <table v-if="csvData.length > 0" class="min-w-full bg-white border border-gray-300 shadow-sm">
              <thead>
                <tr class="bg-blue-500 text-white">
                  <th v-for="(value, index) in header" :key="index" class="px-6 py-3 text-left font-semibold">
                    {{ value }}
                  </th>
                </tr>
              </thead>
              <tbody>
              <tr v-for="(row, rowIndex) in displayedData" :key="rowIndex" class="hover:bg-gray-50 transition-all">
                <!-- Loop through properties and exclude the last one -->
                <td v-for="(value, key, colIndex) in row" :key="colIndex" class="px-6 py-4 whitespace-nowrap">
                  {{ colIndex < Object.keys(row).length - 1 ? value : '' }}
                </td>
              </tr>
            </tbody>

            </table>
          </div>
            <!-- Pagination controls at the bottom right corner -->
          <div v-if="totalPages > 1" class="mt-4 mr-4 flex items-center justify-end">
            <button @click="prevPage" :disabled="currentPage === 1" class="mr-2 px-4 py-2 bg-gray-300 rounded">Prev</button>

            <!-- Use <template> tag for v-for directive -->
            <template v-for="page in dynamicPageNumbers" :key="page"  >
              <div
                class="px-2 py-2 bg-gray-300 rounded cursor-pointer"
                :class="{ 'font-semibold': page === currentPage }"
                @click="goToPage(page)"
              >
                {{ page }}
              </div>
            </template>

            <button @click="nextPage" :disabled="currentPage === totalPages" class="ml-2 px-4 py-2 bg-gray-300 rounded">Next</button>
          </div>
      </div>

      </div>
    </div>

  </template>
  
  <script>
  import Dexie from 'dexie';
  
  const db = new Dexie('CSVDataDB');
    db.version(1).stores({
    csvData: '++id, *data',
    headerData: '++id, *header',
  });

  
  export default {
    data() {
      return {
        csvData: [],
        header: [],
        pageSize: 10,
        currentPage: 1,
        jumpToPage: 1,
      };
    },
    computed: {
    displayedData() {
      return this.csvData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize + 1);
    },
    totalPages() {
      return Math.ceil(this.csvData.length / this.pageSize);
    },
    dynamicPageNumbers() {
      const remainingPages = Math.min(10, this.totalPages - this.currentPage);
      return Array.from({ length: remainingPages }, (_, index) => this.currentPage + index);
    },
  },
    methods: {
      async loadCSVDataAndHeader() {
        try {
          console.log("try to load data");
          // Fetch CSV data from the 'csvData' store
          this.csvData = await db.csvData.toArray();
  
          // Fetch header data from the 'headerData' store
          const headerData = await db.headerData.toArray();
          this.header = headerData.length > 0 ? JSON.parse(headerData[0].header) : [];
          console.log("data loaded");
        } catch (error) {
          console.error('Error loading data from IndexedDB:', error);
        }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      goToPage(page) {
        this.currentPage = page;
      },
    },
    created() {
      this.loadCSVDataAndHeader();
    },
  };
  </script>
  
  <style scoped>
  .table-container {
    overflow-x: auto;
    max-width: 100%;
  }
  
  /* Additional styles for the darker header */
  table th {
    background-color: #2c3e50; /* Change to the desired darker color */
  }
  </style>
  