<template>
  <div class="bg-dark-blue min-h-screen flex items-center justify-center">
    <div class="custom-width-form relative bg-white p-8 rounded shadow-md height-form text-center">
      <h1 class="text-2xl font-semibold mt-6 mb-6 text-center">{{ $t('summary_header') }}</h1>
      <div class="modal-icon flex flex-row justify-center"><font-awesome-icon class="icon-style" icon="circle-check" /></div>
      <div class= "mx-12 mt-12 items-center text-center  place-content-center">
        <div class="relative flex items-center text-center  place-content-center mt-4">
          <div class="w-1/2">
            <label class="block font-medium text-left pl-32" for="inline-full-name">{{$t('inputed_academic')}}</label>
          </div>
          <div class="w-1/2 text-left">
            <label  class="block font-bold text-left inline-block"> : &nbsp;</label> 
            <label class="font-bold italic text-left" for="inline-full-name">{{ datatableCounts.inputedAcademicYear}}</label>
            <label>{{$t('year')}}</label>
          </div>
        </div>

        <div class="relative flex items-center text-center  place-content-center mt-4 ">
          <div class="w-1/2">
            <label class="block font-medium text-left pl-32" for="inline-full-name">{{$t('selected_export')}}</label>
          </div>
          <div class="w-1/2 text-left">
            <label  class="block font-bold text-left inline-block"> : &nbsp;</label> 
            <label class=" font-bold text-left italic" for="inline-full-name">{{ datatableCounts.selectedExportOption }}</label>
          </div>
        </div>

        <div class="relative flex items-center text-center  place-content-center mt-4  ">
          <div class="w-1/2">
            <label class="block font-medium text-left pl-32" for="inline-full-name">{{$t('exported_teacher')}}</label>
          </div>
          <div class="w-1/2 text-left">
            <label  class="block font-bold text-left inline-block"> : &nbsp;</label> 
            <label class="font-bold text-left italic" for="inline-full-name"> {{ datatableCounts.totalExportedTeacher }} </label>
            <label>{{$t('num_exported_teacher')}}</label> 
          </div>
        </div>

        <div class="relative flex items-center text-center  place-content-center mt-4 ">
          <div class="w-1/2">
            <label class="block font-medium text-left pl-32" for="inline-full-name">{{$t('exported_student')}}</label>
          </div>
          <div class="w-1/2 text-left">
            <label  class="block font-bold text-left inline-block"> : &nbsp;</label> 
            <label class="font-bold text-left italic" for="inline-full-name">{{ datatableCounts.totalExportedStudent }}</label>
            <label>{{$t('num_exported_student')}}</label>
          </div>
        </div>
      </div>
  
      <div class=" flex justify-center mt-8 ">
        <button @click="toCsvStudent" class="csv-group-button w-full mt-4  mr-4 px-4 py-2 bg-dark-blue text-white p-2 rounded hover:bg-blue-700 ">{{ $t('button_back') }}</button>
        <button @click="exportCSV" :disabled="exporting" class="csv-group-button ml-4 w-full mt-4  px-4 py-2  bg-orange text-white p-2 rounded hover:bg-yellow-600">
          <span v-if="exporting">
            <font-awesome-icon class="" icon="spinner" />
            {{ $t('button_download') }}
          </span>
          <span v-else>
            <font-awesome-icon class="" icon="download" />
            {{ $t('button_download') }}
          </span>
        </button>
      </div>
      <button @click="toCsvReader" class="csv-group-button2 mt-8 px-4 py-2 bg-dark-blue text-white p-2 rounded hover:bg-blue-700 ">{{ $t('return2import') }}</button>

    
        <div v-if="!isOnline" class="text-center text-red-600 mt-4">
          {{$t('youareoffline')}}
        </div>

        <ExportLogComponent :isExportLog="export_log"/>
        <SystemVersionComponent :SystemVersion="system_version"/>

        <!-- Modal Invalid Data -->
        <ModalInvalidData :ShowModal="isModalVisible" :InvalidData="emptyAcademicYear" @close="closeModal"/>
        <!-- Modal Alert -->
        <ModalAlertComponent :Message="errorMessage" :ShowModal="showAlertModal" @close="closeModalAlert"/>
        <ModalWarningComponent :Message="errorMessage" :ShowModal="showWarningModal" @cancel="closeWarning" @ok="okWarning"/>

    </div>
  </div>
  
</template>

<script>
  import Papa from 'papaparse';
  import Dexie from 'dexie';
  import ModalInvalidData from './ModalInvalidData.vue';
  import ExportLogComponent from './ExportLog.vue';
  import ModalAlertComponent from './ModalAlert.vue';
  import SystemVersionComponent from './SystemVersion.vue';
  import ModalWarningComponent from './ModalWarning.vue';

  const db = new Dexie('CSVDataDB');

  db.version(4).stores({
    csvData : '++id, *data',
    headerData: '++id, *header',
    exportedCsv :'++id, *data',
    localLog :'++id, *data'
  });

export default {
  props: {
    exportOption: String,
  },
  components:{
    ModalInvalidData,
    ModalAlertComponent,
    SystemVersionComponent,
    ModalWarningComponent,
    ExportLogComponent

  },
  data() {
    return {
      sortColumn: null,
      tableSearch : '',
      logoutIcon:'Logout.png',
      csvData: [],
      csvDataNew: [],
      csvDataDisplay: [],
      csvStudentExport: [],
      showWarningModal : false,
      selectedExportOption : null,
      inputedAcademicYear : 0,
      totalExportedTeacher : 0,
      totalExportedStudent : 0,
      exporting: false,
      isChecked:true,
      checkboxStudents: [],
      isModalVisible : false,
      isModalClientVisible : false,
      client_id : localStorage.client_id,
      system_version : process.env.VUE_APP_SYSTEM_VERSION,
      emptyAcademicYear :[],
      showAlertModal : false,
      errorMessage : '',
      isOnline: navigator.onLine
    };
  },

  mounted() {

  },
  computed:{
    
      datatableCounts() {
        return {
          selectedExportOption : this.selected_export,
          inputedAcademicYear : this.inputedacademicYear,
          totalEmptyEnrollment : this.totalEmptyEnrollment,
          totalExportedTeacher : this.totalExportedTeacher,
          totalExportedStudent : this.totalExportedStudent,

        };
      },
    },  
    methods: {
      async  logLocal(level, action, description, isOnline) {
        const logsToAdd = [];
        let timestamp = Date.now();          
          const mappedlogLocal = {
                datetime : timestamp,
                client_id : this.client_id,
                level: level.toUpperCase(),
                action: action,
                description : description
          }
          logsToAdd.push(mappedlogLocal); 

          if (isOnline !== "") {
              await db.localLog.bulkAdd(logsToAdd);
          } else {
              return "";
          }
      },
      async  deleteOldLogs() {
        const currentDate = Date.now();
        const threeDaysBefore = new Date(currentDate);
        threeDaysBefore.setDate(threeDaysBefore.getDate() - 3);
        const threeDaysBeforeTimestamp = threeDaysBefore.getTime();

        await db.localLog
          .filter(row => row.datetime < threeDaysBeforeTimestamp)
          .delete();
      },
      logout(){
        this.logLocal("info","Logout in summary page", 'Success, Logout, data reseted',  this.isOnline);
        localStorage.removeItem('session_data');
        localStorage.removeItem('client_id');
        localStorage.removeItem('api_key');
        localStorage.removeItem('input_year');
        localStorage.removeItem('system_version');
        localStorage.removeItem('isUpload');
        localStorage.removeItem('is_filter');
        localStorage.removeItem('checkedStudents');
        localStorage.removeItem('checkedTeachers');
        db.csvData.clear();
        db.headerData.clear();
        db.exportedCsv.clear();
        this.$router.push('/');
      },
      async loadCSVDataAndHeader() {
        try {
         
          this.inputedacademicYear = localStorage.input_year;

          const  selectedExportOption = localStorage.selectedExportOption;
          if(selectedExportOption == 0){
            this.selected_export = this.$t("importRadio1");
          }else{
            this.selected_export = this.$t("importRadio2");

          }

          this.csvDataTeacher = await db.csvData
                        .filter(row => row.classRole !== "児童生徒")
                        .filter(row => row.enabledUser !== "0")
                        .filter(row => row.isLocal !== "1")
                        .toArray();

      
          let checkedTeachers = localStorage.checkedTeachers;
          this.totalExportedTeacher =  checkedTeachers != '' ? checkedTeachers.split(',').map(Number).length : 0;

          const checkedStudents = localStorage.checkedStudents;
          this.totalExportedStudent = checkedStudents != '' ? checkedStudents.split(',').map(Number).length : 0;
        
        } catch (error) {
          this.errorMessage = this.$t("errorLoadingDataStudent")+' '+error;
          this.logLocal("error",this.errorMessage, 'Error, loading student data from IndexedDb', this.isOnline);
          this.showAlertModal = true;
          this.exporting = false;
          this.loading = false;
        }
      },
      toCsvStudent(){
        this.logLocal("info","Back to Student Page", 'Success, Back to Student Page', this.isOnline);
        this.$router.push('/csv-student');
      },
      async toCsvReader(){
        this.logLocal("warn","Warning to Reader Page modal ", 'Modal data will reset popup, if continue to Reader Page',  this.isOnline);
        this.errorMessage = this.$t("warningtocsvreader");
        this.showWarningModal = true;
      },
      showWarning(){
        this.showWarningModal = true;
      },
      closeWarning(){
        this.showWarningModal = false;
        this.logLocal("info","Cancel button clicked", 'Cancel button in modal warning is clicked',  this.isOnline);
      },
      okWarning(){
        this.showWarningModal = false;
        this.logLocal("info","Back to Reader Page", 'Success, Back to Reader Page, Data Reseted',  this.isOnline);
        db.csvData.clear();
        db.headerData.clear();
        this.$router.push('/csv-reader');
      },
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      },
      closeModalAlert() {
        this.showAlertModal = false;
      },
      async exportCSV() {
      
        if(this.exporting){
          this.errorMessage = this.$t("errorOnProgressExporting");
          this.logLocal("error",this.errorMessage, 'Error, Error, Previous export operation is still on process', this.isOnline);
          this.showAlertModal = true;
          this.exporting = false;
          this.loading = false;
          return;
        }

        // if (this.csvDataNew.length === 0) {
        //   console.warn('Export in progress or no data to export.');
        //   this.errorMessage = this.$t("errorEmptyExporting");
        //   let api_key =  localStorage.getItem('api_key');
        //   this.$helpers.logError("error",this.errorMessage, 'There is no user information to export.', api_key, this.isOnline);
        //   this.logLocal("error",this.errorMessage, 'There is no user information to export.', this.isOnline);
        //   this.showAlertModal = true;
        //   this.exporting = false;
        //   this.loading = false;
        //   return;
        // }

        const selectedExportOption = localStorage.getItem('selectedExportOption');
        if (selectedExportOption === '0') {
          this.exporting = true;
          // csvStudentExport: [],
          let dataExportStudent  = await db.exportedCsv.toArray();
          let mappedEntryStudent  = dataExportStudent.map(entry => {
            const mappedEntryStudent = {
                // id : entry.id,
                sso_id: entry.sso_id,
                user_type: entry.user_type,
                admission_year:  entry.admission_year,
                user_id: null,
                nickname: entry.nickname,
                Le_password: null, 
                Ma_password: null, 
                Mi_password: null, 
                Ch_password: null, 
                Sv_password: null,
                Ts_password: null 
              };

              return mappedEntryStudent;
          });

          setTimeout(() => {      
            try {
              let csvContent = "\uFEFF";
              csvContent += Papa.unparse(mappedEntryStudent, {
                header: true,
                delimiter: ',',
                encoding: this.selectedEncoding, 
              });
              const blob = new Blob([csvContent], { type: `text/csv;charset=${this.selectedEncoding};` });
              const link = document.createElement('a');

              link.href = URL.createObjectURL(blob);
              var currentDate = new Date();
              var dateString = currentDate.toISOString().slice(0, 10).replace(/-/g, ''); 
              var timeString = currentDate.toTimeString().slice(0, 8).replace(/:/g, ''); 

              var filename = this.$t("student") +  '_' + dateString + '_' + timeString + '.csv';
              link.download = filename;
              link.click();
              this.logLocal("info","Success export teacher data", 'Success, export student data in summary page',  this.isOnline);

            } catch (error) {
              this.errorMessage = this.$t("errorExportingStudentData")+' '+error;
              this.logLocal("error",this.errorMessage, 'Error, exporting student data',  this.isOnline);
              this.showAlertModal = true;
              this.exporting = false;
              this.loading = false;
            } finally {
              setTimeout(() => {
                this.exporting = false;
                this.loading = false;
              }, 2000);
          
            }}, 2000);
          


          // eksport data teacher
          let checkedTeachers = localStorage.checkedTeachers.split(',').map(Number);
          let filteredTeacherData = this.csvDataTeacher.filter((item) => {
              return checkedTeachers.includes(item.id);
          });
          const mappedDataTeacher = filteredTeacherData.map(entry => {
            const isTeacher = entry.classRole === '教員';
            const isStudent = entry.classRole === '児童生徒';

            const mappedEntryTeacher = {
              sso_id: entry.username,
              user_type: isTeacher ? 2 : isStudent ? 1 : null,
              admission_year: isTeacher ? null : entry.admission_year,
              user_id: null,
              nickname: entry.name,
              Le_password: null, 
              Ma_password: null, 
              Mi_password: null, 
              Ch_password: null, 
              Sv_password: null,
              Ts_password: null 
            };

            return mappedEntryTeacher;
          });
          setTimeout(() => {
            try {
              let csvContent = "\uFEFF";
              csvContent += Papa.unparse(mappedDataTeacher, {
                header: true,
                delimiter: ',',
                encoding: this.selectedEncoding, 
              });
              const blob = new Blob([csvContent], { type: `text/csv;charset=${this.selectedEncoding};` });
              const link = document.createElement('a');

              link.href = URL.createObjectURL(blob);
              var currentDate = new Date();
              var dateString = currentDate.toISOString().slice(0, 10).replace(/-/g, ''); 
              var timeString = currentDate.toTimeString().slice(0, 8).replace(/:/g, ''); 

              var filename = this.$t("teacher") + '_' + dateString + '_' + timeString + '.csv';
              link.download = filename;
              link.click();
              this.logLocal("info","Success export teacher data", 'Success, export teacher data in summary page', this.isOnline);

            } catch (error) {
              this.errorMessage = this.$t("errorExportingTeacherData")+' '+error;
              this.logLocal("error",this.errorMessage, 'Error, exporting teacher data', this.isOnline);
              this.showAlertModal = true;
              this.exporting = false;
              this.loading = false;
            } finally {
              setTimeout(() => {
                this.exporting = false;
                this.loading = false;
              }, 2000);
            }
          }, 2000);

        } else if (selectedExportOption === '1') {
          // Logic for combined export
          this.exporting = true;
          this.loading = true;

          //data student
          let dataExportStudent  = await db.exportedCsv.toArray();
          let mappedDataStudent  = dataExportStudent.map(entry => {
            const mappedEntryStudent = {
                // id : entry.id,
                sso_id: entry.sso_id,
                user_type: entry.user_type,
                admission_year:  entry.admission_year,
                user_id: null,
                nickname: entry.nickname,
                Le_password: null, 
                Ma_password: null, 
                Mi_password: null, 
                Ch_password: null, 
                Sv_password: null,
                Ts_password: null 
              };

              return mappedEntryStudent;
          });

          //data teacher
          let checkedTeachers = localStorage.checkedTeachers.split(',').map(Number);
          let filteredTeacherData = this.csvDataTeacher.filter((item) => {
              return checkedTeachers.includes(item.id);
          });
          const mappedDataTeacher = filteredTeacherData.map(entry => {
            const isTeacher = entry.classRole === '教員';
            const isStudent = entry.classRole === '児童生徒';

            const mappedEntryTeacher = {
              sso_id: entry.username,
              user_type: isTeacher ? 2 : isStudent ? 1 : null,
              admission_year: isTeacher ? null : entry.admission_year,
              user_id: null,
              nickname: entry.name,
              Le_password: null, 
              Ma_password: null, 
              Mi_password: null, 
              Ch_password: null, 
              Sv_password: null,
              Ts_password: null 
            };

            return mappedEntryTeacher;
          });
          if(mappedDataStudent.length == 0 && mappedDataTeacher.length == 0){
            this.errorMessage = this.$t("errorEmptyExporting");
            this.logLocal("error",this.errorMessage, 'Error, exporting teacher data',  this.isOnline);
            this.showAlertModal = true;
            this.exporting = false;
            this.loading = false;
            return;
          }
         
          const mappedData = [...mappedDataStudent, ...mappedDataTeacher];

          setTimeout(() => {
            try {
              let csvContent = "\uFEFF";
              csvContent += Papa.unparse(mappedData, {
                header: true,
                delimiter: ',',
                encoding: this.selectedEncoding, 
              });
              const blob = new Blob([csvContent], { type: `text/csv;charset=${this.selectedEncoding};` });
              const link = document.createElement('a');

              link.href = URL.createObjectURL(blob);
              var currentDate = new Date();
              var dateString = currentDate.toISOString().slice(0, 10).replace(/-/g, ''); 
              var timeString = currentDate.toTimeString().slice(0, 8).replace(/:/g, ''); 

              var filename = this.$t("combined") + '_' + dateString + '_' + timeString + '.csv';
              link.download = filename;
              link.click();
            
            } catch (error) {
              this.errorMessage = this.$t("errorExporting")+' '+error;
              this.logLocal("error",this.errorMessage, 'Error, exporting student/teacher data', this.isOnline);
              this.showAlertModal = true;
              this.exporting = false;
              this.loading = false;
            } finally {
              setTimeout(() => {
                this.exporting = false;
                this.loading = false;
              }, 2000);
            }
          }, 2000);
        } else {
            // Handle other cases or provide a default behavior
            let errorMessageExportOption = 'Invalid export option:' +  this.exportOption;
            console.warn(errorMessageExportOption);
            this.logLocal("error",errorMessageExportOption,  'Invalid export option',  this.isOnline);
            this.errorMessage = errorMessageExportOption;
            this.exporting = false;
            this.loading = false;
          }
      },
      findAllOccurrences(str) {
        var searchStr = '年'
        var searchLength = searchStr.length;
        var startIndex = 0;
        var indices = [];

        while ((startIndex = str.indexOf(searchStr, startIndex)) > -1) {
          indices.push(startIndex);
          startIndex += searchLength;
        }
        return indices;
      }
    },
    created() {
      this.loadCSVDataAndHeader();
      this.deleteOldLogs();
    }
};
</script>

<style scoped>
  .icon-style{
    font-size: 150px;
    color: #163ECB;
  }

  .custom-width-form{
    min-width: 1024px;
    padding-bottom: 60px;
  }
  .logout-container{
    position: absolute;
    top: 10px;
    right: 15px;
  }
  .logout-icon{
    width:25px;
    height:auto;
  }
  .height-form{
    min-height:85vh;
    max-height:98vh;
  }
  .icon-properties{
    bottom: 25px;
    right: 9px;
  }

  .csv-group-button{
    width: 100%;
    max-width: 240px;
    /* height: auto; */
  }

    .csv-group-button2{
    width: 100%;
    max-width: 300px;
    /* height: auto; */
  }

@media only screen and (max-width: 1900px) {
  .table-container{
    max-height: 300px;
  }
}

</style>
