<!-- src/components/DynamicProgressBar.vue -->
<template>
    <transition name="fade" appear>
        <div class="modal-backdrop" v-if="ShowModal"> 
    
        </div>
    </transition>
    <transition name="pop" appear>
        <div class="modal rounded" v-if="ShowModal">
            <section class="modal-body">
                <slot name="body">
                    <div class="modal-icon flex flex-row justify-center"><font-awesome-icon class="icon-style" icon="times-circle" /></div>
                    <div id="modalTitle" class="modalTitle text-center pt-2 text-2xl">{{ $t('modalTitleError') }}</div>
                    <div id="modalContent" class="modalTitle text-center pt-2 text-base">{{ Message }}</div>
                </slot>
            </section>
            <footer class="modal-footer flex pb-5">
                <slot name="footer">
                    <button type="button" class="rounded w-6/12 mx-auto bg-dark-blue btn-close px-2 py-2 text-white" @click="close">{{$t('modalClose')}}</button>
                </slot>
            </footer>
        </div>
    </transition>
</template>
  
<script>
  export default {
    name: 'ModalAlertComponent',
    props:{
      Message : String,
      ShowModal : Boolean
    },
    methods: {
      close() {
        this.$emit('close');
      },
    },
  };
</script>
<style scoped>
  .icon-style{
    font-size: 100px;
    color: red;
  }
  .modal-backdrop {
    /* position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center; */
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 998;
    background: #2c3e50;
    opacity: 0.6;
  }

  .modal {
    /* background: #FFFFFF;
    box-shadow: 2px 2px 20px 1px;
    overflow-x: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    height: auto; */
    position: absolute;
    top: 30%;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
    width: 100%;
    max-width: 300px;
    height: auto;
    padding: 2rem;
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
    background: #FFF;
    z-index: 999;
    /* transform: none; */
  }

  .modal-header{
    padding: 15px;
    display: flex;
  }

  .modal-header {
    position: relative;
    border-bottom: 1px solid #eeeeee;
    color: #4AAE9B;
    justify-content: space-between;
  }

  .modal-footer {
    flex-direction: column;
    justify-content: flex-end;
  }

  .modal-body {
    position: relative;
    padding: 20px 10px;
  }
  .fade-enter-active,.fade-leave-active {
    transition: opacity .4s linear;
  }
 .fade-enter-from,.fade-leave-to {
     opacity: 0;
  }
  .pop-enter-active,.pop-leave-active {
    transition: transform 0.4s cubic-bezier(0.5, 0, 0.5, 1), opacity 0.4s linear;
  }
  .pop-enter-from,.pop-leave-to {
    opacity: 0;
    transform: scale(0.3) translateY(-50%);
  }

</style>

