// src/router.js
import { createRouter, createWebHistory } from 'vue-router';
import CSVReader from './components/CSVReader.vue';
import Login from './components/LoginForm.vue';
import CSVPreview from './components/CSVPreview.vue';
import CSVTeacher from './components/CSVTeacher.vue';
import CSVStudent from './components/CSVStudent.vue';
import ExportSummary from './components/ExportSummary.vue';



const routes = [
  { name:'Login', path: '/', component: Login, meta:{title:'L-Gate名簿コンバーター'}  },
  { name:'CsvReader', path: '/csv-reader', component: CSVReader, meta:{title: 'L-Gate名簿コンバーター', requiresAuth:false} },
  { path: '/csv-preview', component: CSVPreview, meta:{title: 'L-Gate名簿コンバーター', requiresAuth:false} },
  { path: '/csv-teacher', component: CSVTeacher, meta:{title: 'L-Gate名簿コンバーター', requiresAuth:false} },
  { path: '/csv-student', component: CSVStudent, meta:{title: 'L-Gate名簿コンバーター', requiresAuth:false} },
  { path: '/export-summary', component: ExportSummary, meta:{title: 'L-Gate名簿コンバーター', requiresAuth:false} },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta?.title ?? 'CSV Converter'
  // const isAuthenticated = localStorage.session_data;
  const isAuthenticated = true;
  // const localUrl = localStorage.routes;
  const isUpload = localStorage.isUpload;
  console.log(from);

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!isAuthenticated) {
      next({ name: 'Login' });
    } else {
      if (to.fullPath == "/csv-teacher" && isUpload !== "1") {
       next({ name: 'CsvReader' });
      } else if (to.fullPath == "/csv-student" && isUpload !== "1") {
        next({ name: 'CsvReader' });
      } else if (to.fullPath == "/export-summary" && isUpload !== "1") {
        next({ name: 'CsvReader' });
      } else {
        next();
      }
    }
  } else {
    if (isAuthenticated) {
      if(to.fullPath == "/"){
        next({ name: 'CsvReader' });
      }
      if (to.fullPath == "/csv-teacher" && isUpload !== "1") {
        next({ name: 'CsvReader' });
 
      }else if (to.fullPath == "/csv-student" && isUpload !== "1") {
        next({ name: 'CsvReader' });
      } else if (to.fullPath == "/export-summary" && isUpload !== "1") {
        next({ name: 'CsvReader' });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
