<template>
  <div class="bg-dark-blue min-h-screen flex items-center justify-center">
    <div class="custom-width relative bg-white p-8 rounded shadow-md height-form">
      <HeaderButtonComponent/>
      <h1 class="text-2xl font-semibold mt-4 mb-4 text-center">{{ $t('student_header1') }}</h1>
      <h1 class="text-xl font-semibold mt-4 mb-4 text-center">{{ $t('student_header2') }}</h1>
      <div class="text-center align-center">
        <h2 class="text-xl font-semibold  text-center inline-block align-middle">{{ $t('student_header3') }}</h2>
      <label class="switch ml-4 mb-4">
        <input type="checkbox" id="checkbox_filter" v-on:change="filterEmptyAcademicYear" v-model="filterAcademicYear">
        <span class="slider"></span>
      </label>
      </div>
      <div class="search-container relative flex flex-row justify-end">
        <input type="text" id="table_search" :placeholder="$t('th_name')" v-on:keyup.enter="filterName" v-model="tableSearch" class="w-64 m mt-4 mb-4 p-2 pr-11 text-left text-black border border-black rounded focus:outline-none focus:border-blue-500 placeholder-gray-600"/>
        <font-awesome-icon icon="magnifying-glass" @click="filterName" class="absolute text-2xl icon-properties cursor-pointer" />
      </div>

      <div class="table-container overflow-x-auto border border-black ">
          <!-- <table class="table-auto bg-white border border-gray-300 shadow-sm" :class="[classheader.length <= 4 ? 'w-full' : 'w-max']"> -->
          <table class="table-fixed bg-white border border-gray-300 shadow-sm" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'w-full' : 'w-max']">
          <thead>
            <tr>
              <th class="table-header px-4 py-2 text-center border border-black text-white text-sm" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'checkbox-width' : '']">
                {{ $t('th_activate') }}
              </th>
              <th class="sortable-header table-header px-4 py-2 text-center border border-black text-white text-sm" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'name-width' : '']" @click="toggleSort('name')">
                {{ $t('th_name') }}
                <span v-if="shouldShowIndicator('name', 'asc')">▲</span>
                <span v-if="shouldShowIndicator('name', 'desc')">▼</span>
              </th>
              <th class="sortable-header table-header px-4 py-2 text-center border border-black text-white text-sm" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'username-width' : '']" @click="toggleSort('username')">
                {{ $t('th_email') }}
                <span v-if="shouldShowIndicator('username', 'asc')">▲</span>
                <span v-if="shouldShowIndicator('username', 'desc')">▼</span>
              </th>
              <th width="100px" class="sortable-header table-header px-4 py-2 text-center border border-black text-white text-sm" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'academic-width' : '']" @click="toggleSort('academic_year')">
                {{ $t('th_academic_year') }}
                <span v-if="shouldShowIndicator('academic_year', 'asc')">▲</span>
                <span v-if="shouldShowIndicator('academic_year', 'desc')">▼</span>
              </th>
              <th v-for="(value, index) in classheader" :key="index" class="sortable-header table-header px-4 py-2 text-center border border-black text-white text-sm" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'class-width' : '']" @click="toggleSort(`kelas${index + 1}`)">
                {{ $t('kelas') }} {{ index + 1 }}
                <span v-if="shouldShowIndicator(`kelas${index + 1}`, 'asc')">▲</span>
                <span v-if="shouldShowIndicator(`kelas${index + 1}`, 'desc')">▼</span>
              </th>
            </tr>
          </thead>
          <tbody class="border border-black">
            <tr v-for="(row, rowIndex) in displayedData" :key="rowIndex" class="hover:bg-gray-50 transition-all border border-black" >
              <td class="border border-black px-2 py-1 text-center text-sm leading-3 " :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'checkbox-width' : '']"><input type="checkbox" class="h-5 w-5" :value="row.id" v-model="checkboxStudents"/></td>
              <td class="border text-sm leading-3 border-black px-2 py-1 text-center" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'name-width' : '']">{{ row.name  || '' }}</td>
              <td class="border text-sm leading-3 border-black px-2 py-1 text-center" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'username-width' : '']">{{ row.username  || ''    }}</td>
              
              <td v-if="row.academic_year !== '' || row.academic_year_updated" class="border text-sm leading-3 border-black px-2 py-1 text-center" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'academic-width' : '']">
                  <input v-if="row.academic_year_updated" type="text" :id="'input_year_render' + rowIndex" v-model="inputYear.parent_id[row.id]" class="text-center h-6 w-20 p-2 text-left text-black border border-black rounded focus:outline-none focus:border-blue-500" @input="handleInputYear(row.id)"/>
                  <span v-else>{{ row.academic_year }}</span>
              </td>
              <td v-else class="border text-sm leading-3 border-black px-2 py-1 text-center" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'academic-width' : '']">
                  <input type="text" :id="'input_year_render' + rowIndex" v-model="inputYear.parent_id[row.id]" class="text-center h-6 w-20 p-2 text-left text-black border border-black rounded focus:outline-none focus:border-blue-500" @input="handleInputYear(row.id)"/>
              </td>
              <td v-for="(header, index) in classheader " :key="index" class="border text-sm leading-3 border-black px-2 py-1 text-center" :class="[classheader.length < 4 || (csvDataDisplay.length <= 0 && classheader.length < 9) ? 'class-width' : '']">
                {{ row[`kelas${index + 1}`] || "" }}
              </td>
            </tr>
          </tbody>
        </table>
        <div v-if="csvDataDisplay.length <= 0">
          <h2 class="text-center font-bold"> {{ $t('noStudentFound') }}</h2>
        </div>
      </div>
       <!-- Pagination controls at the bottom right corner -->
       <div v-if="totalPages > 1" class="mt-4 mr-4 flex items-center justify-end">
            <button @click="prevPage" :disabled="currentPage === 1" :class="{ 'cursor-default' : currentPage === 1}"  class="text-sm mr-2 px-4 py-2 bg-gray-300 rounded">{{$t('paginationPrev')}}</button>
            <div class="flex flex-row" v-if=" currentPage != 1 && firstPage != 1">
              <div class="text-sm mx-1 px-2 py-2 bg-gray-300 rounded cursor-pointer" :class="{ 'font-semibold': page === currentPage }" @click="goToPage(1)">1</div>    
              <div class="text-sm mx-1 px-2 py-2 cursor-default" >...</div>      
            </div>
            <!-- Use <template> tag for v-for directive -->
            <template v-for="page in dynamicPageNumbers" :key="page"  >
              <div
                class="text-sm mx-1 px-2 py-2 bg-gray-300 rounded cursor-pointer"
                :class="{ 'font-semibold': page === currentPage }"
                @click="goToPage(page)"
              >
                {{ page }}
              </div>
            </template>
            <div class="flex flex-row" v-if="totalPages > numShown && currentPage != totalPages && (totalPages - currentPage >= numShown / 2)">
              <div class="text-sm mx-1 px-2 py-2 cursor-default" >...</div>      
              <div class="text-sm mx-1 px-2 py-2 bg-gray-300 rounded cursor-pointer" :class="{ 'font-semibold': page === currentPage }" @click="goToPage(totalPages)">{{ totalPages }}</div>    
            </div>
            <button @click="nextPage" :disabled="currentPage === totalPages" :class="{ 'cursor-default' : currentPage === totalPages}" class="text-sm ml-2 px-4 py-2 bg-gray-300 rounded">{{$t('paginationNext')}}</button>
          </div>
          <p class="text-l pt-2 font-semibold text-center my-2">{{ $t('total_records') }} : {{ datatableCounts.totalRecords }} {{ $t('satuan') }} ({{ $t('total_filtered') }} : {{ datatableCounts.totalFiltered }}  {{ $t('satuan') }}、 {{ $t('total_invalid') }} : {{ datatableCounts.totalInvalid }} {{ $t('satuan') }}、 {{ $t('no_decision') }} : {{ datatableCounts.totalEmptyEnrollment }} {{ $t('satuan') }})</p>
          <!-- <p class="text-l pt-2 font-semibold text-center my-2"> </p> -->
          <p class=" text-l pt-2 font-semibold text-center">  {{ $t('notPerformSSO') }} </p>
          <div class="form-import flex flex-row justify-center mt-4 mb-2">
            <button class="csv-group-button mt-4 px-4 py-2  bg-dark-blue text-white p-2 rounded hover:bg-blue-700" @click="toCsvTeacher">{{ $t('button_back') }}</button>
            <div class="csv-group-button mx-4">
              <button  @click="toExportSummary"  class="w-full  mt-4 px-4 py-2   bg-dark-blue text-white p-2 rounded hover:bg-blue-700">{{$t('button_next')  }}</button>
            </div>
          </div>

          <div v-if="!isOnline" class="text-center text-red-600 mt-4">
            {{$t('youareoffline')}}
          </div>

        <ExportLogComponent :isExportLog="export_log"/>
        <SystemVersionComponent :SystemVersion="system_version"/>

        <!-- Modal Invalid Data -->
        <ModalInvalidData :ShowModal="isModalVisible" :InvalidData="invalidAcademicYear" :InvalidDataTitle="InvalidDataYearTitle" @close="closeModal"/>
        <!-- Modal Alert -->
        <ModalAlertComponent :Message="errorMessage" :ShowModal="showAlertModal" @close="closeModalAlert"/>
    </div>
  </div>
</template>


<script>
  // import Papa from 'papaparse';
  import Dexie from 'dexie';
  //import { ref } from 'vue';
  import ModalInvalidData from './ModalInvalidData.vue';
  import ExportLogComponent from './ExportLog.vue';
  import ModalAlertComponent from './ModalAlert.vue';
  import SystemVersionComponent from './SystemVersion.vue';
  import HeaderButtonComponent from './headerButton.vue';

  


  const db = new Dexie('CSVDataDB');

  db.version(4).stores({
    csvData : '++id, *data',
    headerData: '++id, *header',
    exportedCsv :'++id, *data',
    localLog : '++id, *data'
  });

export default {
  props: {
    exportOption: String,
  },
  components:{
    ModalInvalidData,
    ModalAlertComponent,
    SystemVersionComponent,
    ExportLogComponent,
    HeaderButtonComponent
  },
  data() {
    return {
      sortColumn: null,
      tableSearch : '',
      logoutIcon:'Logout.png',
      csvData: [],
      csvDataNew: [],
      csvDataDisplay: [],
      header : [],
      pageSize: 10,
      currentPage: 1,
      jumpToPage: 1,
      totalRecords: 0,
      totalFiltered: 0,
      totalInvalid: 0,
      totalEmptyEnrollment : 0,
      classheader: [],
      exporting: false,
      isChecked:true,
      numShown : 10,
      firstPage : 0,
      inputYear: {
        parent_id: []
      },
      inputYearUpdate: {
        parent_id: []
      },
      filterAcademicYear : localStorage.is_filter === 'true',
      sortOrders: {},
      checkboxStudents: [],
      isModalVisible : false,
      isModalClientVisible : false,
      client_id : localStorage.client_id,
      system_version : process.env.VUE_APP_SYSTEM_VERSION,
      invalidAcademicYear :[],
      InvalidDataYearTitle : [],
      showAlertModal : false,
      errorMessage : '',
      classHeaderCount : 0,
      exportedCsvStudent : [],
      isFilterOn : false,
      isOnline: navigator.onLine,
    };
  },
  mounted() {
    this.toggleSort('name');
  },
  computed:{
      displayedData() {
        const startIdx = (this.currentPage - 1) * this.pageSize;
        const endIdx = this.currentPage * this.pageSize;
        return this.csvDataDisplay.slice(startIdx, endIdx);
      },
      totalPages() {
        return Math.ceil(this.csvDataDisplay.length / this.pageSize);
      },
      dynamicPageNumbers() {
        const numShown = Math.min(this.numShown, this.totalPages);
        let first = this.currentPage - Math.floor(numShown / 2);
        first = Math.max(first, 1);
        first = Math.min(first, this.totalPages - numShown + 1);
        this.setFirstPage(first);
        return [...Array(numShown)].map((k,i) => i + first);
      },
      datatableCounts() {
        return {
          totalRecords: this.totalRecords,
          totalFiltered: this.totalFiltered,
          totalInvalid: this.totalInvalid,
          totalEmptyEnrollment : this.totalEmptyEnrollment
        };
      },
    },  
  methods: {
    updateOnlineStatus() {
      this.isOnline = navigator.onLine;
    },
    setFirstPage(page){
      this.firstPage = page;
    },
    logout(){
      this.logLocal("info","Logout in student page", 'Success, Logout, data reseted',  this.isOnline);
      localStorage.removeItem('session_data');
      localStorage.removeItem('client_id');
      localStorage.removeItem('api_key');
      localStorage.removeItem('system_version');
      localStorage.removeItem('input_year');
      localStorage.removeItem('isUpload');
      localStorage.removeItem('is_filter');
      localStorage.removeItem('checkedStudents');
      localStorage.removeItem('checkedTeachers');
      db.csvData.clear();
      db.headerData.clear();
      db.exportedCsv.clear();
      this.$router.push('/');
    },
    async loadCSVDataAndHeader() {
        try {
          const prevCheckedStudents = localStorage.checkedStudents?.split(',').map(Number);
    
          // this.csvDataTeacher = await db.csvData
          //               .filter(row => row.classRole !== "児童生徒")
          //               .filter(row => row.username.trim() !== '')
          //               .filter(row => row.enabledUser !== "0")
          //               .filter(row => row.isLocal !== "1")
          //               .toArray();

          // //CSV yg di view display (Old)
          // this.csvData = await db.csvData
          //               .filter(row => row.classRole == "児童生徒")
          //               .filter(row => row.username.trim() !== '')
          //               // .filter(row => row.enabledUser !== "0" && row.isLocal !== "1")
          //               .toArray();

            // total semua 
          this.totalRecords = await db.csvData
                        .filter(row => row.classRole == "児童生徒")
                        .filter(row => row.username.trim() !== '')
                        .count();

          this.totalFiltered = await db.csvData
                   .filter(row => row.classRole == "児童生徒")
                   .filter(row => row.enabledUser !== "0")
                   .filter(row => row.isLocal !== "1")
                   .count()

          this.totalInvalid = this.totalRecords - this.totalFiltered;

        // Fetch header data from the 'headerData' store with a filter for properties starting with "classNumber"
          
        const headerData = await db.headerData.toArray();
          this.header = headerData.length > 0 ? JSON.parse(headerData[0].header) : [];

        const classheaderData = await db.headerData
            .where('header')
            .notEqual('')
            .toArray();

          if (classheaderData.length > 0) {
                const headerValue = classheaderData[0].header;
                const parsedHeader = JSON.parse(headerValue);
                const filteredClassNumbers = parsedHeader.filter(property => property.startsWith("kelas"));
                this.classheader = filteredClassNumbers;

            } else {
              this.errorMessage = this.$t("errorEmptyHeaderData");
              this.logLocal("error",this.errorMessage, 'Error, getting header data',  this.isOnline);
              this.showAlertModal = true;
              this.exporting = false;
              this.loading = false;
              return;
            }

                   //CSV yg di view display (new)
          this.csvDataNew = await db.csvData
                        .filter(row => row.classRole == "児童生徒")
                        .filter(row => row.enabledUser !== "0")
                        .filter(row => row.isLocal !== "1")
                        .toArray();
          console.log("this.csvDataNew", this.csvDataNew)
          const mappedcsvDataNew = this.csvDataNew.map(entry => {      
            const mappedEntry = {
                id: entry.id,
                name: entry.name,
                username: entry.username,
                academic_year: entry.academic_year
            };
            // let classArray =[];
            // const inputYear = localStorage.input_year;
            this.classheader.forEach((header, index) => {
                mappedEntry[`kelas${index + 1}`] = entry[`kelas${index + 1}`];
                // classArray.push(mappedEntry[`kelas${index + 1}`]);
            });
            // classArray = classArray.filter((item) => {
            //   return item.split(' ')[0].includes(inputYear);
            // })
            // this.checkboxStudents.push(entry.id);

            if(prevCheckedStudents == undefined){
                this.checkboxStudents.push(entry.id);
              }else{
                if(prevCheckedStudents.includes(entry.id)){
                  this.checkboxStudents.push(entry.id);
                }
              }
  
            // if(classArray.length < 1){
            //   mappedEntry['academic_year'] = "";
            // }else if(classArray.length == 1){
            //   mappedEntry['academic_year'] = this.setAcademicYear(classArray[0]);
            // }else if(classArray.length > 1){
            //   let nonEmptyYears = classArray.map(classItem => this.setAcademicYear(classItem)).filter(year => year !== '');
            //   if (nonEmptyYears.length === 0) {
            //       mappedEntry['academic_year'] = ""; 
            //   } else if (nonEmptyYears.some(year => year !== nonEmptyYears[0])) {
            //       mappedEntry['academic_year'] = ""; 
            //   } else {
            //       mappedEntry['academic_year'] = nonEmptyYears[0]; 
            //   }
            // }else{
            //   return ""
            // }
            mappedEntry['is_det_system'] = mappedEntry['academic_year'] == '' || !mappedEntry['academic_year'] ? 0 : 1;
            return mappedEntry;
          });
          localStorage.checkedStudents = this.checkboxStudents;

          let mappedcsvDataNewSorted = this.sortArray(mappedcsvDataNew, 'name', 'asc')
          
          this.csvDataDisplay = mappedcsvDataNewSorted;
          this.csvDataNew = mappedcsvDataNewSorted;

          await this.getExportedCSV();
          await this.deleteOldLogs();

          console.log("logLocal",  this.logLocalData);


          //update csvdatanew berdasarkan exported student
            this.csvDataNew.forEach(entry => {
              const matchingExportedCSV = this.exportedCsvStudent.find(exportedEntry => exportedEntry.id === entry.id);
              if (matchingExportedCSV && matchingExportedCSV.admission_year) {
                  if (entry.academic_year === '') {
                      entry.academic_year = matchingExportedCSV.admission_year;
                      entry.academic_year_updated = true;
                      this.inputYear.parent_id[entry.id] = entry.academic_year;
                  }
              }
          });
          this.totalEmptyEnrollment = this.csvDataNew.filter(row => !row.academic_year).length;

          this.filterEmptyAcademicYear()

        } catch (error) {
          this.errorMessage = this.$t("errorLoadingDataStudent")+' '+error;
          this.logLocal("error",this.errorMessage, 'Error, loading student data from IndexedDb',  this.isOnline);
          this.showAlertModal = true;
          this.exporting = false;
          this.loading = false;
        }
      },
      async getExportedCSV() {
        this.exportedCsvStudent = await db.exportedCsv.toArray();
      },
      async deleteOldLogs() {
        const currentDate = Date.now();
        const threeDaysBefore = new Date(currentDate);
        threeDaysBefore.setDate(threeDaysBefore.getDate() - 3);
        const threeDaysBeforeTimestamp = threeDaysBefore.getTime();

        await db.localLog
          .filter(row => row.datetime < threeDaysBeforeTimestamp)
          .delete();
      },
      toCsvTeacher(){
        localStorage.checkedStudents = this.checkboxStudents;
        localStorage.is_filter = this.isFilterOn;
        this.logLocal("info","Back to Teacher Page", 'Success, Back to Teacher Page',  this.isOnline);
        this.$router.push('/csv-teacher');
      },
      async toExportSummary(){
        let invalidAcademicYearData = [];
        this.invalidAcademicYear = [];


        for (var id of Object.keys(this.inputYear.parent_id)) {
            const rowToUpdate = this.csvDataNew.find(row => row.id == id)

            if(!this.validateYear(this.inputYear.parent_id[id])){
              invalidAcademicYearData.push({ id: id, value: this.inputYear.parent_id[id], username:rowToUpdate.username });
            }
        }
        if(invalidAcademicYearData.length > 0){
          this.invalidAcademicYear = invalidAcademicYearData;
          this.InvalidDataYearTitle = this.$t('modalInvalidDataTitle'); 
          this.logLocal("info","Invalid Academic Year", 'Invalid acdemic year, modal popup ',  this.isOnline);
          this.showModal();
          return;
        }


        for (var key7 of Object.keys(this.inputYear.parent_id)) {
          const rowToUpdate = this.csvDataNew.find(row => row.id == key7)

          if (rowToUpdate) {
          

            rowToUpdate.academic_year = this.inputYear.parent_id[key7];
            console.log("rowToUpdate.academic_year", rowToUpdate.academic_year);
            this.logLocal("info",`Empty  Academic Year changed for username ${rowToUpdate.username}`, `Updated academic year =  ${rowToUpdate.academic_year}`, this.isOnline);
            rowToUpdate.academic_year_updated = true;
          }
        }
        let filteredStudentData = this.csvDataNew.filter((item) => {
            return this.checkboxStudents.includes(item.id);
        });

        let arr_emptyAcademicYear = filteredStudentData.filter((item) => {
            return !item.academic_year || item.academic_year == "";
        });


        if (arr_emptyAcademicYear.length > 0) {
            this.invalidAcademicYear = arr_emptyAcademicYear;
            this.InvalidDataYearTitle = this.$t('modalEmptyDataTitle'); 
        }

        this.totalEmptyEnrollment = this.invalidAcademicYear.length;
        if(this.invalidAcademicYear.length > 0){
          this.showModal();
          return;
        }
        const mappedDataStudent = filteredStudentData.map(entry => {
          const mappedEntryStudent = {
              id : entry.id,
              sso_id: entry.username,
              user_type: 1,
              admission_year:  entry.academic_year,
              nickname: entry.name,
              Le_password: null, 
              Ma_password: null, 
              Mi_password: null, 
              Ch_password: null, 
              Sv_password: null, 
            };
          return mappedEntryStudent;
        });
        db.exportedCsv.clear();
        await db.exportedCsv.bulkAdd(mappedDataStudent);
        localStorage.checkedStudents = this.checkboxStudents;
        localStorage.is_filter = this.isFilterOn;
        this.logLocal("info","Go to Summary Page", 'Success, Go to Export Summary Page',  this.isOnline); 
        this.$router.push('/export-summary');
      },
      async  logLocal(level, action, description, isOnline) {
            // let message_log = `[${timestamp}] [${this.client_id}] ${lvl_lbl.toUpperCase()}: ${action} "${description}"`;
        let client_id = this.client_id; 
        const logsToAdd = [];
        let timestamp = Date.now();          
          const mappedlogLocal = {
                datetime : timestamp,
                client_id : client_id,
                level: level.toUpperCase(),
                action: action,
                description : description
          }
          logsToAdd.push(mappedlogLocal); 

          if (isOnline !== "") {
              await db.localLog.bulkAdd(logsToAdd);
          } else {
              return "";
          }
      },
      nextPage() {
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      },
      prevPage() {
        if (this.currentPage > 1) {
          this.currentPage--;
        }
      },
      goToPage(page) {
        this.currentPage = page;
      },
      showModalClientId() {
        this.isModalClientVisible = true;
      },
      closeModalClientId() {
        this.isModalClientVisible = false;
      },
      toggleSort(column) {
        // Tambahkan logic untuk sorting
        if (this.sortColumn === column) {
          // Toggle between ascending and descending order
          this.sortOrders[column] = this.sortOrders[column] === 'asc' ? 'desc' : 'asc';
          this.csvDataNew = this.sortArray(this.csvDataNew, column, this.sortOrders[column]);
          this.csvDataDisplay = this.sortArray(this.csvDataDisplay, column, this.sortOrders[column]);
        } else {
          this.sortColumn = column;
          // Set default sorting order to ascending
          this.sortOrders[column] = 'asc';
          this.csvDataNew = this.sortArray(this.csvDataNew, column, this.sortOrders[column]);
          this.csvDataDisplay = this.sortArray(this.csvDataDisplay, column, this.sortOrders[column]);
        }
      },
      showModal() {
        this.isModalVisible = true;
      },
      closeModal() {
        this.isModalVisible = false;
      },
      closeModalAlert() {
        this.showAlertModal = false;
      },
      async handleInputYear(rowId){
        this.inputYear.parent_id[rowId] = this.inputYear.parent_id[rowId].replace(/\D/g, '').slice(0, 4);
      },
      sortArray(array, column, order) {
        return array.sort((a, b) => {
          const valueA = a[column];
          const valueB = b[column];

          if (typeof valueA === 'number' && typeof valueB === 'number') {
            // Numerical sorting
            return (order === 'asc' ? 1 : -1) * (valueA - valueB);
          } else {
            // Alphabetical sorting
            const stringA = String(valueA).toLowerCase();
            const stringB = String(valueB).toLowerCase();
            return (order === 'asc' ? 1 : -1) * stringA.localeCompare(stringB, 'ja-JP');
          }
        });
      },
      shouldShowIndicator(column, order) {
        if (this.sortColumn === column) {
          if (!this.sortOrders[column]) {
            this.sortOrders[column] = 'asc';
          }
          return this.sortOrders[column] === order;
        }
        return false;
      },
      setAcademicYear(param){
        const inputYear = localStorage.input_year;
        const indexYear = this.findAllOccurrences(param);

        let isValidYear = this.validateYear(inputYear);

        if(!isValidYear){
          this.errorMessage = this.$t("errorInputYear");
          this.logLocal("error",this.errorMessage, 'Error, loading student data from IndexedDb',  this.isOnline);
          this.showAlertModal = true;
          this.exporting = false;
          this.loading = false;
          return;
        }

        if(indexYear.length <= 1){
          return '';
        }else{
          if(Number.isInteger(parseInt(param.charAt(indexYear[1] - 1)))){
            return (parseInt(inputYear) - parseInt(param.charAt(indexYear[1] - 1))) + 1;
          }
          return '';
        }
      },
      validateYear(params) {
        if(params && params != ""){
          const year = parseInt(params);
          return !isNaN(year) && year >= 1000 && year <= 9999;
        }
        return true;
      },
      async filterEmptyAcademicYear(){
        this.invalidAcademicYear = [];

        for (var key8 of Object.keys(this.inputYear.parent_id)) {
          const rowToUpdate = this.csvDataNew.find(row => row.id == key8)
          if (rowToUpdate) {
            rowToUpdate.academic_year = this.inputYear.parent_id[key8];
            rowToUpdate.academic_year_updated = true;
          }
        }
        console.log('Prev Value',this.csvDataNew);
        console.log('Update Value',this.csvDataNew.filter(row => row.is_det_system == 0));
        this.totalEmptyEnrollment = this.csvDataNew.filter(row => !row.academic_year || row.academic_year == "").length;
        if(this.filterAcademicYear){
          this.currentPage = 1;
          this.isFilterOn = true;
          // this.csvDataDisplay = this.csvDataNew.filter(row => row.is_det_system == 0);
          this.filterName();
          this.logLocal("info","Switch filter manual year clicked", 'Switch filter manual year clicked in student page',  this.isOnline); 
        }else{
          this.isFilterOn = false;
          this.filterName();
          // this.csvDataDisplay = this.csvDataNew;
        }
      },
      filterEmail(){
        this.currentPage = 1;
        if(this.tableSearch != ''){
          // this.csvDataDisplay = this.csvDataNew.filter(row => row.username.includes(this.tableSearch));
          !this.isFilterOn ? this.csvDataDisplay = this.csvDataNew.filter(row => row.username.includes(this.tableSearch)) : this.csvDataDisplay = this.csvDataNew.filter(row => row.username.includes(this.tableSearch) && row.is_det_system == 0);
          let searchWord = '"' + this.tableSearch + '"';
          this.logLocal("info","Searching by email data in student page", 'Searching by email ' + searchWord  + ' word',  this.isOnline); 
        }else{
          !this.isFilterOn ? this.csvDataDisplay = this.csvDataNew : this.csvDataDisplay = this.csvDataNew.filter(row => row.is_det_system == 0);
        }
      },
      filterName(){
        this.currentPage = 1;
        if(this.tableSearch != ''){
          // this.csvDataDisplay = this.csvDataNew.filter(row => row.username.includes(this.tableSearch));
          // !this.isFilterOn ? this.csvDataDisplay = this.csvDataNew.filter(row => row.name.includes(this.tableSearch)) : this.csvDataDisplay = this.csvDataNew.filter(row => row.name.includes(this.tableSearch) && row.is_det_system == 0);
          this.csvDataDisplay = !this.isFilterOn ? this.csvDataNew.filter(row => row.name.includes(this.tableSearch)) : this.csvDataNew.filter(row => row.name.includes(this.tableSearch) && row.is_det_system == 0);
          let searchWord = '"' + this.tableSearch + '"';
          this.logLocal("info","Searching by name data in student page", 'Searching by name ' + searchWord  + ' word',  this.isOnline); 

        }else{
          !this.isFilterOn ? this.csvDataDisplay = this.csvDataNew : this.csvDataDisplay = this.csvDataNew.filter(row => row.is_det_system == 0);
        }
      },
      findAllOccurrences(str) {
        var searchStr = '年'
        var searchLength = searchStr.length;
        var startIndex = 0;
        var indices = [];

        while ((startIndex = str.indexOf(searchStr, startIndex)) > -1) {
          indices.push(startIndex);
          startIndex += searchLength;
        }
        return indices;
      }
  },
  created() {
    //db.exportedCsv.clear();
    this.loadCSVDataAndHeader();
    this.deleteOldLogs();
  }
};
</script>

<style scoped>

  .checkbox-width{
    width: 22%;
  }
  .name-width{
    width: 60%;
  }
  .username-width {
    width: 70%;
  }
  .academic-width{
    width: 25%;
  }
  .class-width {
    width: 33.33%;
  }
  .custom-width{
    min-width: 1200px;
  }

.checkbox-width{
width: 22%;
}
.name-width{
  width: 60%;
}
.username-width {
  width: 70%;
}
.academic-width{
  width: 25%;
}
.class-width {
  width: 33.33%;
}

.checkbox-width-max{
  width: 6%;
}
.name-width-max{
  width: 20%;
}
.username-width-max{
  width: 20%;
}
.academic-width-max{
  width: 5%;
}
.class-width-max{
  width: 10%;
}
  .table-container {
    overflow-x: auto;
    max-width: 1200px;
    width:100%;
    height: 100%;
    max-height: 600px;
    overflow-y: auto;
    margin-left: auto;
    margin-right: auto;

  }

  .sortable-header{
    cursor: pointer;
    user-select: none;
  }

  .table-header {
    background-color: #004c97;
  }

  .rotate-180 {
    transform: rotate(180deg);
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #2196F3;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .logout-container{
    position: absolute;
    top: 10px;
    right: 15px;
  }
  .logout-icon{
    width:25px;
    height:auto;
  }
  .height-form{
    min-height:85vh;
  }
  .icon-properties{
    bottom: 25px;
    right: 9px;
  }

  .csv-group-button{
    width: 100%;
    max-width: 128px;
    height: auto;
  }

@media only screen and (max-width: 1900px) {
  .table-container{
    max-width: unset;
    max-height: 300px;
  }
  .custom-width{
    width: 100%;
    max-width: 90%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}

</style>
