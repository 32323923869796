<template>
  <div>
    <router-view />

    <!-- Dialog -->
    <div v-if="showDialog" class="dialog">
      <p>新しいバージョンが入手可能です</p>
      <button @click="refreshPage">リフレッシュ</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      showDialog: false,
      showLoading: false
    };
  },
  created() {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/service-worker.js')
        .then(reg => {
          reg.addEventListener('updatefound', () => {
            // New service worker found
            const newWorker = reg.installing;
            newWorker.addEventListener('statechange', () => {
              if (newWorker.state === 'installing') {
                // Service worker is being installed, show loading indicator
                this.showLoading = true;
              } else if (newWorker.state === 'installed') {
                // Service worker updated
                if (navigator.serviceWorker.controller) {
                  // Hide loading indicator and show dialog to user
                  this.showLoading = false;
                  this.showDialog = true;
                }
              }
            });
          });
        })
        .catch(error => {
          console.error('Error during service worker registration:', error);
        });
    }
  },
  methods: {
    refreshPage() {
      window.location.reload();
    }
  }
};
</script>

<style>
.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 9998;
}
</style>
