<!-- src/components/SystemVersion.vue -->
<template>
    <div class="version-container absolute bottom-2  text-xl">
      <div class="relative">
        <span class="text-sm text-right" for="system_version">{{ $t('modalTitleSystemVersion') }}:</span>
        <input class="text-sm bg-transparent text-right italic w-10" type="text" :disabled="true" ref="SystemVersion" id="system_version" :value="SystemVersion">
     
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SystemVersionComponent',
    props: {
      SystemVersion: String,
      // isVisibleTooltip : Boolean,
    },
    data() {
      return {
        isVisibleTooltip: false,
      };
    },
    methods: {
      showTooltip() {
        console.log(this.SystemVersion);
        this.isVisibleTooltip = true;
        // Get the text field
        var copyText = this.$refs.SystemVersion;
  
        // Select the text field
        copyText.select();
        navigator.clipboard.writeText(copyText.value);
        setTimeout(() => (this.isVisibleTooltip = false), 3000);
      },
    },
  };
  </script>
  
  <style>
  .version-container {
    width: 50%;
    bottom: 10px;
    right: 10px;
    text-align: right;

  }
  .tooltip {
    position: relative;
    display: inline-block;
  }
  .tooltip .tooltiptext {
    position: absolute;
    width: 90px;
    background-color: #555;
    color: #fff;
    text-align: right;
    padding: 5px 0;
    border-radius: 6px;
    z-index: 1;
  
    /* Position the tooltip */
    top: 0;
    left: 125%;
  }
  .w-300 {
    max-width: 300px;
  }
  </style>
  