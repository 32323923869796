<template>
    <div class="eksport_log-container absolute pt-2 text-xl">
     <div class="relative bg-transparent">
            <button @click="exportLog" :disabled="exporting" class="text-xl text-blue-500 cursor-pointer">
            <span v-if="exporting">
                <font-awesome-icon class="" icon="spinner" />
            </span>
            <span v-else>
                <font-awesome-icon class="" icon="download" />
            </span>
            </button>
        </div>
    </div>

</template>
  
<script>
import Dexie from 'dexie';
import Papa from 'papaparse';

const db = new Dexie('CSVDataDB');

db.version(4).stores({
    localLog :'++id, *data'
});

export default {
    name: 'ExportLogComponent',
    props:{
        isExportLog : Boolean,
    },
    data() {
        return {
            client_id : localStorage.client_id,
            isOnline: navigator.onLine,
            exporting: false,
        };
    },
    methods:{
        async exportLog(){
            if (this.exporting) return; 
            
            this.exporting = true;

            let dataLocalLog  = await db.localLog.toArray();
            let mappedEntryLogLocal  = dataLocalLog.map(entry => {
                const mappedLogLocal = {
                    datetime : entry.datetime,
                    client_id : entry.client_id,
                    level: entry.level,
                    action: entry.action,
                    description : entry.description
                }

                return mappedLogLocal;
            });

            setTimeout(async () => {
                try {
                    let csvContent = "\uFEFF";
                    csvContent += Papa.unparse(mappedEntryLogLocal, {
                        header: true,
                        delimiter: ',',
                        encoding: this.selectedEncoding, 
                    });
                    const blob = new Blob([csvContent], { type: `text/csv;charset=${this.selectedEncoding};` });
                    const link = document.createElement('a');

                    link.href = URL.createObjectURL(blob);
                    var currentDate = new Date();
                    var dateString = currentDate.toISOString().slice(0, 10).replace(/-/g, ''); 
                    var timeString = currentDate.toTimeString().slice(0, 8).replace(/:/g, ''); 

                    var filename = this.$t("logs") +  '_' + dateString + '_' + timeString + '.csv';
                    link.download = filename;
                    link.click();
                    this.logLocal("info","Success export local logs", 'Success, export local log data ',  this.isOnline);

                } catch (error) {
                    this.errorMessage = this.$t("errorExportingStudentData")+' '+error;
                    this.logLocal("error",this.errorMessage, 'Error, exporting local logs data',  this.isOnline);
                    this.showAlertModal = true;
                } finally {
                    this.exporting = false;
                }
            }, 3000); 
        },
        async logLocal(level, action, description, isOnline) {
            const logsToAdd = [];
            let timestamp = Date.now();          
            const mappedlogLocal = {
                datetime : timestamp,
                client_id : this.client_id,
                level: level.toUpperCase(),
                action: action,
                description : description
            }
            logsToAdd.push(mappedlogLocal); 

            if (isOnline !== "") {
                await db.localLog.bulkAdd(logsToAdd);
            } else {
                return "";
            }
        },
    }
};
</script>

<style>
    .eksport_log-container{
        width: 50%;
        left: 12px;
        bottom: 12px;
        text-align: left;

    }
    .tooltip {  
        position: relative;
        display: inline-block;
    }
    .tooltip .tooltiptext {
        position: absolute;
        width: 90px;
        background-color: #555;
        color: #fff;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        z-index: 1;
    
        /* Position the tooltip */
        top: 0;
        left: 125%;

    }
    /* .eksport_log{
        width: fit-content;
        left: 340px;
        top: 2px;
    } */
    .w-300{
        max-width: 300px;
    }
</style>

