<!-- src/components/CSVReader.vue -->
<template>
  <div class="bg-dark-blue">
    <div class="p-8 flex flex-col items-center justify-center h-screen max-h-full">
      <div class="custom-width-form bg-white relative p-8 shadow-md rounded-md ">
        <HeaderButtonComponent/>
        <div class="import-text-header mt-7">
          <h1 class=" text-3xl pt-3 font-semibold text-center mb-2">{{$t('importTitle')}}</h1>
          <p class=" text-xl pt-2 font-semibold text-center">{{$t('importText1')}}</p>
          <!-- <p class=" text-xl pt-2 font-semibold text-center">{{$t('importText2')}}</p> -->
        </div>
  
        <div class="relative form-import form-import-custom flex items-center text-center justify-between place-content-center mt-6">
          <div class="width-390">
            <label class="block  font-bold text-left mb-1 mb-0 pr-4" for="inline-full-name"><span>①&nbsp;</span>{{$t('importLabel1')}}</label>
          </div>
          <div class="text-left">
              <input
              type="file"
              accept=".csv"
              @change="handleFileUpload"
              class="w-64 mt-4 p-2 text-left  border border-black rounded focus:outline-none focus:border-blue-500"
            />
          </div>
        </div>
          <div  v-if="!isValidFile" class="absolute w-max custom-warning text-center text-red-600 ">
            {{ errorMessageFile}}
          </div >
            <!-- Not Valid Header message -->
            <div v-if="!isValidHeader" class="absolute w-max custom-warning text-center text-red-600 ">
            {{errorMessageHeader}}
          </div>
          
        <div class="mt-6 form-import form-import-custom flex items-center text-center justify-between place-content-center">
          <div class="width-390">
            <label class="block font-bold text-left mb-1 mb-0 pr-4" for="inline-full-name"><span>②&nbsp;</span>{{$t('importLabel4')}}</label>
          </div>
          <div class="relative text-right">
            <input type="text" id="input_year" v-model="inputYear" class="w-64 p-2 text-left text-black border border-black rounded focus:outline-none focus:border-blue-500" maxlength="4"  @input="handleInputYear" />
            <div v-if="!isYearInputValid" class="absolute custom-warning invalid-year-class text-center text-red-600">
              {{ errorMessageYear }}
            </div>
          </div>
        </div>
  
        <div class="radio-button mt-4">
          <p class="text-center text-sm custom-text-container mt-6">{{ $t('importRoleNotice1') }}</p>
          <p class="text-center text-sm custom-text-container mt-2">{{ $t('importRoleNotice2') }}</p>
          <p class="font-bold mt-6 mr-auto ml-auto text-left" style="width: 75%;"><span>③&nbsp;</span>{{ $t('importLabel3') }}</p>
          <div class="flex justify-center gap-8 mt-6">
            <div class="radio1">
              <input type="radio" name="exportOptions" id="export_option_1" value="0"  v-model="selectedExportOption"  @click="handleRadioButtonClick('0')"/>
              <label class="" for="export_option_1">{{ $t('importRadio1') }}</label>
            </div>
            <div class="radio2">
              <input type="radio" name="exportOptions" id="export_option_2" value="1"   v-model="selectedExportOption"  @click="handleRadioButtonClick('1')"/>
              <label class="" for="export_option_2">{{ $t('importRadio2') }}</label>
            </div>
          </div>
        </div>
  
        <div v-if="loading" class="text-center text-gray-600">
             <DynamicProgressBar :progress="progress" :isVisible="loading" />
        </div>
        <div class="form-import flex flex-row justify-evenly">
        <button @click="toCsvTeacher" class="w-full mt-6 px-4 py-2 bg-dark-blue text-white p-2 rounded hover:bg-blue-700">{{ $t('button_upload') }}</button>
  
        </div>
        <!-- Offline status message -->
        <div v-if="!isOnline" class="text-center text-red-600 mt-4">
          {{ $t('youareoffline') }}
        </div>

        <ExportLogComponent :isExportLog="export_log"/>
        <SystemVersionComponent :SystemVersion="system_version"/>
        <ModalAlertComponent :Message="errorMessage" :ShowModal="showAlertModal" @close="closeAlert"/>
        
      </div>
    </div>
  </div>
</template>

<script>
import Papa from 'papaparse';
import Dexie from 'dexie';
import DynamicProgressBar from './DynamicProgressBar.vue';
import ExportLogComponent from './ExportLog.vue';
import ModalAlertComponent from './ModalAlert.vue';
import * as EncodingJapanese from 'encoding-japanese';
import SystemVersionComponent from './SystemVersion.vue';
import HeaderButtonComponent from './headerButton.vue';

// import axios from 'axios';

const db = new Dexie('CSVDataDB');

  db.version(4).stores({
    csvData : '++id, *data',
    headerData: '++id, *header',
    exportedCsv :'++id, *data',
    localLog :'++id, *data'
  });

export default {
  components: {
    DynamicProgressBar,
    ModalAlertComponent,
    SystemVersionComponent,
    ExportLogComponent,
    HeaderButtonComponent
  },
  data() {
    return {
      previousUrl: '',
      csvData: [],
      loading: false,
      pageSize: 10,
      currentPage: 1,
      jumpToPage: 1,
      headerCsvData:[],
      isOnline: navigator.onLine, // Initial online status,
      exporting: false,
      selectedEncoding: 'utf-8', 
      selectedFile: null,
      progress: 0,
      logoutIcon:'Logout.png',
      errorMessageFile: null,
      errorMessageHeader: null,
      isValidHeader: false,
      isValidFile: true,
      selectedExportOption: '0',
      inputYear: new Date().getFullYear(),
      isModalVisible: false,
      system_version : process.env.VUE_APP_SYSTEM_VERSION,
      showAlertModal : false,
      errorMessage : '',
      logOfflineData : [],
      export_log : false,
      classheader: [],

    };
  },
  computed: {
    isYearInputValid() {
      const isValid = /^\d{4}$/.test(this.inputYear);
      return isValid;
    },
  },
  watch: {
    isYearInputValid(newValue) {
      this.errorMessageYear = newValue ? null : this.$t('errorMessageYear');
      this.logLocal("error","Please enter a valid 4-digit year.", 'Input Year is not valid',  this.isOnline); 
    },
  },
  methods: {
    async handleFileUpload(event) {
      this.isValidFile = true;
      this.errorMessageFile = null;
      this.errorMessageHeader = null;
      const fileInput = event.target;
      const file = event.target.files[0];
      this.selectedFile = event.target.files[0];
      this.logLocal("info",`File inputed`, `File name :  ${file.name}`, this.isOnline)

      if (!file || file.type !== 'text/csv') {        
        this.errorMessageFile = this.$t("invalidfile");
        this.logLocal("error", this.errorMessageFile,'File Input is not valid',  this.isOnline )
        this.loading = false;
        this.isValidFile = false;
        // Reset the file input
        fileInput.value = '';
        this.selectedFile = null;
        
        await db.csvData.clear();
        await db.headerData.clear();
        localStorage.setItem('isUpload', "0");

        return;
      }


      await this.checkFileEncoding(file);

      if(this.isValidFile){
        try {
          const result = await this.parseFile(file);
          this.csvData = result.data;
          this.headerCsvData = result.meta.fields;
  
            // Validate header fields
          const requiredFields = [
            'username', 'password', 'enabledUser', 'isLocal', 'schoolCode',
            'schoolName', 'familyName', 'givenName', 'familyKanaName',
            'givenKanaName', 'renewName', 'renewPassword', 'renewClass',
            'termName', 'className', 'classRole', 'classNumber', 'homeClass'
          ];
  
          const isValidHeader = requiredFields.every(field => this.headerCsvData.includes(field));
  
          if (!isValidHeader) {
            // alert('Invalid CSV file. The header does not contain all required fields.');
            this.errorMessageHeader = this.$t("invalidheader");
            this.logLocal("error",this.errorMessageHeader, 'Invalid CSV File', this.isOnline )
            this.loading = false;
        
            await db.csvData.clear();
            await db.headerData.clear();
            localStorage.setItem('isUpload', "0");
          // Reset the file input
            fileInput.value = '';
            this.selectedFile = null;
            return ;
          }else{
            this.loading = false;
            // return ;
          }
  
  
        } catch (error) {
          console.error('Error handling file upload:', error);
          this.errorMessageFile = this.$t("errorUploadingFile")+' '+error;
          this.logLocal('error', this.errorMessageFile, 'Error, Uploading CSV file', this.isOnline )
  
          this.showAlertModal = true;
          this.errorMessage = this.errorMessageFile;
          this.loading = false;
  
          await db.csvData.clear();
          await db.headerData.clear();
          localStorage.setItem('isUpload', "0");

        // Reset the file input
          fileInput.value = '';
          this.selectedFile = null;
        } finally {
          this.loading = false;
        }
      }
    },
    checkFileEncoding(file){
      return new Promise((resolve, reject) => {
        const fileInput = event.target;

        const reader = new FileReader();

        reader.onload = (e) =>{
          
          const content = new Uint8Array(e.target.result);
  
          const detectedEncoding = EncodingJapanese.detect(content);

          console.log("Detected Encoding: ",detectedEncoding);

         
          if(detectedEncoding != 'UTF8'){
            this.errorMessageFile = this.$t("invalidEncoding");
            this.logLocal("error",this.errorMessageFile, 'File Input is not valid', this.isOnline );
            this.loading = false;
            this.isValidFile = false;
            fileInput.value = '';

          }
          resolve(reader.result);
          // if(!this.isUTF8(content)){
          //   this.errorMessageFile = this.$t("invalidEncoding");
          //   this.loading = false;
          //   this.isValidFile = false;
          // }
        };
        reader.onerror = reject;
        reader.readAsArrayBuffer(file);
      });      
    },
    async parseFile(file) {
      return new Promise((resolve, reject) => {
        Papa.parse(file, {
          complete: resolve,
          header: true,
          encoding: this.selectedEncoding,
          error: reject,
        });
      });
    },
    setAcademicYear(param){
        const inputYear = localStorage.input_year;
        const indexYear = this.findAllOccurrences(param);

        let isValidYear = this.validateYear(inputYear);

        if(!isValidYear){
          this.errorMessage = this.$t("errorInputYear");
          this.logLocal("error",this.errorMessage, 'Error, loading student data from IndexedDb',  this.isOnline);
          this.showAlertModal = true;
          this.exporting = false;
          this.loading = false;
          return;
        }

        if(indexYear.length <= 1){
          return '';
        }else{
          if(Number.isInteger(parseInt(param.charAt(indexYear[1] - 1)))){
            return (parseInt(inputYear) - parseInt(param.charAt(indexYear[1] - 1))) + 1;
          }
          return '';
        }
      },
      validateYear(params) {
        if(params && params != ""){
          const year = parseInt(params);
          return !isNaN(year) && year >= 1000 && year <= 9999;
        }
        return true;
      },
      is_unlimited(row){
        let is_unlimited_count = 0;
        let is_role_teacher = false;
        console.log("LENGTH",this.classheader.length)

        for(let i=0; i<this.classheader.length; i++){
          let termName = i === 0 ? row[`termName`] : row[`termName_${i}`];
          let classRole = i === 0 ? row[`classRole`] : row[`classRole_${i}`];
          if(termName == "無期限"){
            is_unlimited_count += 1;
            if(classRole == "教員" || classRole == "学校管理者"){
              is_role_teacher = true;
            }
          }
        }
        return {unlimited_status:is_unlimited_count == 1 ? true : false, is_teacher: is_role_teacher}
      },
    getKelas(row, classIndex){

      
      if (classIndex === 0) {
        const termName = row[`termName`] || '';
        let className = row[`className`] || '';
        const classRole = row[`classRole`] || '';
        const classNumber = row[`classNumber`] || '';
        const homeClass = row[`homeClass`] || '';

        if(this.isFullWidth(className)){
          className = this.convertToHalf(className);
        }else if(className.includes('-')){
          className = this.convertStripped(className);
        }
        const converted_class = termName+' '+className+' '+classRole+' '+classNumber+' '+homeClass
        return {dataClass:converted_class,dataClassRole:classRole,dataTermName:termName} 

      }
      const termName = row[`termName_${classIndex}`] || '';
      let className = row[`className_${classIndex}`] || '';
      const classRole = row[`classRole_${classIndex}`] || '';
      const classNumber = row[`classNumber_${classIndex}`] || '';
      const homeClass = row[`homeClass_${classIndex}`] || '';

      if(this.isFullWidth(className)){
        className = this.convertToHalf(className);
      }else if(className.includes('-')){
        className = this.convertStripped(className);
      }
      const converted_class = termName+' '+className+' '+classRole+' '+classNumber+' '+homeClass
      return {dataClass:converted_class,dataClassRole:classRole,dataTermName:termName} 
      // return converted_class
    },
    isFullWidth(str) {
      for (var i = 0; i < str.length; i++) {
        var charCode = str.charCodeAt(i);
        if ((charCode >= 0xFF00 && charCode <= 0xFFEF) || (charCode >= 0x3000 && charCode <= 0x303F)) {
          return true;
        }
      }
      return false;
    },
    convertToHalf(params) {
      let convertedString = params.replace(/[！-～]/g, halfwidthChar => String.fromCharCode(halfwidthChar.charCodeAt(0) - 0xfee0));
      convertedString = convertedString.includes('-') ? this.convertStripped(convertedString) : convertedString
      return convertedString;
    },
    convertStripped(params) {
      let convertedString = params.replace('-','年');
      let newString = convertedString.slice(0, 3)+ '組' + convertedString.slice(3);
      return newString;
    },
    async saveToIndexedDB() {
      try {
        await db.csvData.clear();
        await db.headerData.clear();

        const rawCSVHeader = this.headerCsvData;
        let actionRawHeader = "Raw header CSV data import";
        this.logLocal("info", actionRawHeader, JSON.stringify(rawCSVHeader), this.isOnline);

        const rawCSVData = this.csvData.map(row => {
          const sanitizedRow = { ...row };

          Object.keys(sanitizedRow).forEach(key => {
            if (typeof sanitizedRow[key] === 'function' || typeof sanitizedRow[key] === 'object') {
              sanitizedRow[key] = String(sanitizedRow[key]);
            }
          });

          return sanitizedRow;
        });

        rawCSVData.forEach(data => {
          let errorMessage = "Raw CSV data import ";
          this.logLocal("info", errorMessage, JSON.stringify(data), this.isOnline);
        });

        const headerCSVData = this.headerCsvData;
          if (headerCSVData.length > 0) {
                const filteredClassNumbers = headerCSVData.filter(property => property.startsWith("classNumber"));
                this.classheader = filteredClassNumbers;
            } else {
              this.errorMessage = this.$t("errorEmptyHeaderData");
              this.logLocal("error",this.errorMessage, 'Error, getting header data',  this.isOnline);
              this.showAlertModal = true;
              this.exporting = false;
              this.loading = false;
              return;
            }
        
        const mappedcsvData = this.csvData.map(entry => {    
            const mappedEntry = {
                name: entry.familyName + ' ' + entry.givenName,
                username: entry.username,
                classRole: entry.classRole,
                enabledUser: entry.enabledUser,
                isLocal: entry.isLocal
            };
            let classArray =[];
            let classRoleArray = [];
            const inputYear = localStorage.input_year;
            const is_unlimited = this.is_unlimited(entry)
            this.classheader.forEach((header, index) => {
                const result = this.getKelas(entry, index);
                // mappedEntry[`kelas${index + 1}`] = this.getKelas(entry, index);
                mappedEntry[`kelas${index + 1}`] = result.dataClass ? result.dataClass : "";
                if(result.dataClassRole && result.dataClassRole != '' && result.dataTermName != "無期限"){
                  classRoleArray.push(result.dataClassRole);
                }
                classArray.push(mappedEntry[`kelas${index + 1}`]);
            });
            classArray = classArray.filter((item) => {
              return item.split(' ')[0].includes(inputYear);
            })

            if(is_unlimited.unlimited_status){
              if(!is_unlimited.is_teacher){
                if(classArray.length > 1){              
                  mappedEntry['classRole'] = this.getRole(classArray,true)
                }else{
                  if(classArray.length > 0){
                    mappedEntry['classRole'] = this.getRole(classArray[0],false)
                  }
                }
              }else{
                mappedEntry['classRole'] = "教員";
              }

            }else{
              const isAllClassRoleEquals = this.allEqual(classRoleArray);
              if(isAllClassRoleEquals){
                mappedEntry['classRole'] = classRoleArray[0] != "学校管理者" ? classRoleArray[0] : "教員" ;
              }else{
                mappedEntry['classRole'] =  "教員" ;
              }
            }
            

        
            if (mappedEntry['classRole'] == "児童生徒" ){
              if(classArray.length < 1){
                mappedEntry['academic_year'] = "";
              }else if(classArray.length == 1){
                const oldAcademicYear = mappedEntry['academic_year'];
                mappedEntry['academic_year'] = this.setAcademicYear(classArray[0]);
                const newAcademicYear = mappedEntry['academic_year'];
                this.logAcademicYearChange(oldAcademicYear, newAcademicYear, mappedEntry.username);
              }else if(classArray.length > 1){
                let nonEmptyYears = classArray.map(classItem => this.setAcademicYear(classItem)).filter(year => year !== '');
                const oldAcademicYear = mappedEntry['academic_year'];
                if (nonEmptyYears.length === 0) {
                    mappedEntry['academic_year'] = ""; 
                } else if (nonEmptyYears.some(year => year !== nonEmptyYears[0])) {
                    mappedEntry['academic_year'] = ""; 
                } else {
                    mappedEntry['academic_year'] = nonEmptyYears[0]; 
                }
                const newAcademicYear = mappedEntry['academic_year'];
                this.logAcademicYearChange(oldAcademicYear, newAcademicYear, mappedEntry.username);
              }else{
                return ""
              }
            }

            return mappedEntry;
        });
     
        let mappedcsvDataValid = mappedcsvData.filter(entry => entry.name !== 'undefined undefined' || entry.username !== "")
        await db.csvData.bulkAdd(mappedcsvDataValid);
        mappedcsvDataValid.forEach(data => {
          let errorMessage = "Save to csvData table in IndexedDB";
          this.logLocal("info", errorMessage, JSON.stringify(data), this.isOnline);
        });

        let headerData = Object.keys(mappedcsvDataValid[0]);
        await db.headerData.add({ header: JSON.stringify(headerData) });
        let errorMessage = "Save to headerData table in IndexedDB";
        this.logLocal("info", errorMessage, JSON.stringify(headerData), this.isOnline);
        
        console.log('Data and header saved to IndexedDB');
        localStorage.setItem('isUpload', "1");


      } catch (error) {
        this.errorMessageFile = this.$t("errorIndexedDb")+' '+error;
        this.logLocal("error",this.errorMessageFile, 'Error, saving data to IndexedDB', this.isOnline );
        this.showAlertModal = true;
        this.errorMessage = this.errorMessageFile;
      }
    },
    getRole(class_value,is_multiple=false){
      if(!is_multiple){
        let role = class_value.split(" ")[2]
        return role.trim() != "学校管理者" ? role.trim() : "教員" ;
      }else{
        let array_class = [];

        class_value.forEach(data => {
          array_class.push(data.split(" ")[2]);
        });

        const result = this.allEqual(array_class)
        if(result){
          return array_class[0].trim() != "学校管理者" ? array_class[0].trim() : "教員" ;
        }else{
          return "教員";
        }
      }
    },
    allEqual(arr){
      const isAllEqual = arr.every(val => val === arr[0]);
      return isAllEqual
    },
    logAcademicYearChange(oldYear, newYear, username) {
        // if (oldYear !== newYear) {
          let action =`Changed Academic Year for username ${username}`;
            this.errorMessage = `Academic year = ${newYear}  `;
            this.logLocal("info", action, this.errorMessage, this.isOnline);
        // }
    },
    findAllOccurrences(str) {
        var searchStr = '年'
        var searchLength = searchStr.length;
        var startIndex = 0;
        var indices = [];

        while ((startIndex = str.indexOf(searchStr, startIndex)) > -1) {
          indices.push(startIndex);
          startIndex += searchLength;
        }
        return indices;
    },
    async handleInputYear(){
      this.inputYear = this.inputYear.replace(/\D/g, '').slice(0, 4);
    },
    showAlert(){
      this.showAlertModal = true;
    },
    closeAlert(){
      this.showAlertModal = false;
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
     // Method to update online status based on window events
    updateOnlineStatus() {
      this.isOnline = navigator.onLine;
    },
    async exportCSV() {
      if (this.csvData.length === 0 || this.exporting) {
        console.warn('Export in progress or no data to export.');
        return;
      }

      this.exporting = true;
      this.loading = true;
      
      setTimeout(() => {
      try {
        const csvContent = Papa.unparse(this.csvData, {
          header: true,
          delimiter: ',',
          encoding: this.selectedEncoding, 
        });
        const blob = new Blob([csvContent], { type: `text/csv;charset=${this.selectedEncoding};` });
        const link = document.createElement('a');


        link.href = URL.createObjectURL(blob);
        link.download = 'exported_data.csv';
        link.click();
      

      } catch (error) {
        console.error('Error exporting CSV:', error);
      } finally {
        this.exporting = false;
        this.loading = false;
     
      }}, 1);
    },
    async toCsvTeacher(){
      if (!this.selectedFile) {
        this.errorMessageFile = this.$t("errorEmptyImporting");
        this.logLocal("error",this.errorMessageFile, 'Error, file import is empty', this.isOnline);
        this.showAlertModal = true;
        this.errorMessage = this.errorMessageFile;
        return;
      }
      if(!this.isValidFile){
        return;
      }
      if(!this.isYearInputValid){
        this.errorMessageFile = this.$t("errorInputYear");
        this.logLocal("error",this.errorMessageFile, 'Error, validating input year', this.isOnline);
        this.showAlertModal = true;
        this.errorMessage = this.errorMessageFile;
        return;
      }
      if(!this.selectedExportOption){
        this.errorMessageFile = this.$t("errorExportOption");
        this.logLocal("error",this.errorMessageFile, 'Error, export option is not selected', this.isOnline);
        this.showAlertModal = true;
        this.errorMessage = this.errorMessageFile;
        return;
      }
      localStorage.input_year = this.inputYear;
      this.logLocal("info","Set Input Year", `Input year value is ${this.inputYear}`, this.isOnline);

      let export_method = "";
      if(this.selectedExportOption == 0){
        export_method = "先生は別CSVにする"
      }else{
        export_method = "先生・児童生徒をまとめる"
      }
      this.logLocal("info","Radio button export method selected", `Selected method = ${export_method}`, this.isOnline);
    
      this.loading = true;
  
        const startTime = Date.now();
        const estimatedTotalTime = 1000; // Adjust estimation (in milliseconds)

        let elapsedTime = 0;

        // Simulate progress with a loop and delay
        while (elapsedTime < estimatedTotalTime) {
          // Update progress based on elapsed time
          this.progress = Math.floor((elapsedTime / estimatedTotalTime) * 100); 
          // Delay for a short interval (e.g., 100 milliseconds)
          await new Promise(resolve => setTimeout(resolve, 100));

          // Update elapsed time
          elapsedTime = Date.now() - startTime;
        }

        // Ensure progress reaches 100%
        this.progress = 100;

        await this.saveToIndexedDB();
      // localStorage.setItem('routes', "/csv-teacher");
      localStorage.removeItem('checkedTeachers');
      localStorage.removeItem('checkedStudents');
      localStorage.setItem('selectedExportOption', this.selectedExportOption);

      this.logLocal("info","Go to Teacher Page", 'Success, Go to Teacher Page', this.isOnline);
      this.$router.push('/csv-teacher');
    },
    async logLocal(level, action, description, isOnline) {
      const logsToAdd = [];
      let timestamp = Date.now();          
        const mappedlogLocal = {
              datetime : timestamp,
              client_id : this.client_id,
              level: level.toUpperCase(),
              action: action,
              description : description
        }
        logsToAdd.push(mappedlogLocal); 

        if (isOnline !== "") {
            await db.localLog.bulkAdd(logsToAdd);
        } else {
            return "";
        }
    },
    async  deleteOldLogs() {
        const currentDate = Date.now();
        const threeDaysBefore = new Date(currentDate);
        threeDaysBefore.setDate(threeDaysBefore.getDate() - 3);
        const threeDaysBeforeTimestamp = threeDaysBefore.getTime();

        await db.localLog
          .filter(row => row.datetime < threeDaysBeforeTimestamp)
          .delete();
    },
    async delete100MBlog(){
        const sizeLocal = await navigator.storage.estimate()
        const sizeLocalMB = (sizeLocal.usage / 1024 / 1024).toFixed(2);
        if(sizeLocalMB > 100){
          await db.localLog.clear();
        }
    },
    logout(){
      this.logLocal("info","Logout in reader page", 'Success, Logout, data reseted',  this.isOnline);
      localStorage.removeItem('session_data');
      localStorage.removeItem('system_version');
      localStorage.removeItem('client_id');
      localStorage.removeItem('input_year');
      localStorage.removeItem('api_key');
      localStorage.removeItem('routes');
      localStorage.removeItem('isUpload');
      localStorage.removeItem('is_filter');
      localStorage.removeItem('checkedStudents');
      localStorage.removeItem('checkedTeachers');
      
      db.csvData.clear();
      db.headerData.clear();
      
      this.$router.push('/');
    },
    handleRadioButtonClick(option) {
      this.selectedExportOption = option;
      localStorage.setItem('selectedExportOption', option);
    },
  },
  created() {
    this.deleteOldLogs();
    this.delete100MBlog();
    localStorage.removeItem('is_filter');
    localStorage.removeItem('checkedStudents');
    localStorage.removeItem('checkedTeachers');
    // Add event listeners for online and offline events
    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  unmounted() {
    // Remove event listeners when the component is destroyed
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
};
</script>

<!-- ...rest of the component remains unchanged -->

<style scoped>
.table-container {
  overflow-x: auto;
  max-width: 100%;
}

/* Additional styles for the darker header */
table th {
  background-color: #2c3e50; /* Change to the desired darker color */
}

.form-import{
  width: 100%;
  max-width: 50%;
  margin-left: auto;
  margin-right: auto;
}
.form-import-custom{
  max-width: 75%;
}
.csv-group-button{
  width: 100%;
  /* max-width: 100px; */
}
.logout-container{
  position: absolute;
  top: 10px;
  right: 10px;
}
.logout-icon{
  width:50px;
  height:auto;
}
.custom-width-form{
  min-width: 1024px;
  padding-bottom: 30px;
}
.clipboard{
  left: 36%;
}
.custom-warning{
  left: 42%;
  transform: translateY(-12px);  
  font-size: 13px;
}
.width-390{
  width: 425px;
}
.custom-text-container{
  max-width: 65%;
  margin-left: auto;
  margin-right: auto;
}
.invalid-year-class{
  left: 0;
}
</style>
