<!-- src/components/SystemVersion.vue -->
<template>
    <div class="header-container cursor-pointer flex flex-row items-end justify-end">
      <div class="cursor-pointer flex flex-row items-baseline gap-x-1	" @click="openManualPage()">
        <div><font-awesome-icon icon="book-open" class="text-lg" /></div>
        <span class="text-lg font-semibold">{{ $t('manualCaption') }}</span>
        <!-- <img class="manual-icon" v-bind:src="'/img/icons/' + imageLogo" />  -->
      </div>
      <!-- <a href="https://www.info.l-gate.net/admin_manual/" class="text-lg underline text-blue-800" target="_blank">{{ $t('manualCaption') }}＞</a> -->
      <!-- <button @click="openManualPage()" class="btn-header bg-orange text-white p-2 rounded hover:bg-blue-700">{{ $t('manualCaption') }}</button> -->
      <!-- <button @click="closeTab()" class="btn-header bg-dark-blue text-white p-2 rounded hover:bg-blue-700">{{ $t('closeTabCaption') }}</button> -->
    </div>
  </template>
  
  <script>
  export default {
    name: 'HeaderButtonComponent',
    data() {
      return {
        imageLogo:'user_manual.svg',
      };
  },
    methods: {
      closeTab() {
        console.log("SINI")
        window.close();
        return false;
      },
      openManualPage(){
        window.open('https://www.info.l-gate.net/admin_manual/', '_blank').focus();
      }
    },
  };
  </script>
  
  <style>
  .header-container {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 100%;
    max-width: 450px;
  }
  .manual-icon{
    width: 25px;
    height: auto;
  }
  .btn-header{
    width: 100%;
    max-width: 200px;
  }
  .w-300 {
    max-width: 300px;
  }
  </style>
  